import React, { useEffect, useState } from 'react';
import Home_Hero_One from '../images/Tattva Hyderabad Promo.mp4'
import Home_Hero_Two from '../images/Tattva Promo 2.mp4'
import Home_Hero_One_Tablet from '../images/Tattva Hyderabad Promo (Tab Version).mp4'
import Home_Hero_Two_Tablet from '../images/Tattva Dubai Promo (Tab Version).mp4'
import Home_Hero_One_Mobile from '../images/Tattva Hyderabad Promo (Mobile Version).mp4'
import Home_Hero_Two_Mobile from '../images/Tattva Dubai Promo (Mobile Version).mp4'
import Carousel from 'react-bootstrap/Carousel';
import { FaXTwitter } from "react-icons/fa6";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css'
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Home_Project_One from '../images/home-project-one-1.jpg';
import Home_Project_Two from '../images/home-project-two.jpg';
import Home_Project_Three from '../images/home-project-three.jpg';
import Home_Project_Four from '../images/home-project-four.jpg';
import Home_Project_Five from '../images/home-project-five.jpg';
import Home_Project_Six from '../images/home-project-six.jpg';
import Home_Project_Seven from '../images/home-project-seven.jpg';
import Home_Project_Eight from '../images/home-project-eight.jpg';
import Home_Project_Nine from '../images/home-project-nine.jpeg';
import Home_Project_Ten from '../images/asbl-project.jpg';
import Home_Project_Eleven from '../images/asbl-spectra.jpg';
import Home_Location_One from '../images/home-location-one.jpg';
import Home_Location_Two from '../images/home-location-two.jpg';
import Home_Location_Three from '../images/home-location-three.jpg';
import Quote_Icon from '../images/quote.png'
import Testi_Line from '../images/testi-line.png'
import Testi_One from '../images/testi-one.jpg'
import Testi_Two from '../images/testi-two.jpg'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Faq from "react-faq-component";
import whatsAppImg from '../images/whatsapp.png'
// Hyderabad Projects
import Trilight from '../images/hyd-projects/Trilight.jpg'
import IRISByRaghava from '../images/hyd-projects/Raghava-Iris.jpg'
import DSRTheWorld from '../images/hyd-projects/Dsr-world.jpeg'
import VamsiramMahanattan from '../images/hyd-projects/Vamsiram-Manhattan.jpg'
import EleganceImperaVilla from '../images/hyd-projects/Elegans-imperia-villa.jpg'
import RajapushpaGreenDale from '../images/hyd-projects/Rajapushpa-Green-Dale.jpg'
import RajapushpaSereneDale from '../images/hyd-projects/Rajapushla-serene-Dale.png'
import HellamarkNature from '../images/hyd-projects/Hallmatk-nature-nest.jpg'
import HellamarkForest from '../images/hyd-projects/Hallmatk-Floresta-vil.png'
import AikaaVilla from '../images/hyd-projects/Aikaa-villas.jpeg'
// Dubai Projects
import DAMACHills from '../images/dubai-projects/DAMAC-Hills.jpg'
import DAMACSuncity from '../images/dubai-projects/DAMAC-Suncity.jpg'
import DanubeDiamondz from '../images/dubai-projects/Danube-Diamondz.png'
import DanubeOceanz from '../images/dubai-projects/Danube Oceanz 2.jpg'
import EMAARMarinaCove from '../images/dubai-projects/Emaar-Marina-Cove.jpg'
import SobhaRealtyOrbis from '../images/dubai-projects/Sobha Realty - Orbis.jpg'
import SobhaRealtySkyscapeAura from '../images/dubai-projects/Sobha Realty - Skyscape Aura.jpg'
import SobhaRealty310RiversideCrescent from '../images/dubai-projects/Sobha Realty - 310 Riverside Crescent.png'
import SobhaRealtyHartlandII from '../images/dubai-projects/Sobha Realty - Hartland II.jpg'
import DAMACIslands from '../images/dubai-projects/DAMAC - Islands.jpg'

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on page load
  }, []);

  const data = {
    rows: [
      {
        title: "What services does Tattva Hi Life offer?",
        content: `Tattva Hi Life provides a comprehensive range of services, including real estate advisory, PropTech solutions, market research, and analytics to help clients make informed property decisions.`,
      },
      {
        title: "How does Tattva Hi Life ensure transparency in real estate transactions?",
        content: `Transparency is achieved through data-driven insights, detailed market analysis, and clear communication, ensuring clients have complete clarity in every transaction.`,
      },
      {
        title: "What is PropTech, and how does Tattva Hi Life use it?",
        content: `PropTech refers to technology-driven solutions in real estate. Tattva Hi Life leverages innovative tools to provide advanced property analytics, virtual property tours, and efficient transaction management.`,
      },
      {
        title: "Can Tattva Hi Life help with both residential and commercial properties?",
        content: `Yes, Tattva Hi Life specializes in both residential and commercial real estate, offering tailored solutions to meet diverse client needs.`,
      },
      {
        title: "Why should I choose Tattva Hi Life over other firms?",
        content: `Tattva Hi Life combines industry expertise, cutting-edge technology, and a customer-centric approach, making it Hyderabad’s trusted name in real estate advisory and market research.`,
      },
    ],

  };

  const styles = {
    // bgColor: 'white',
    // titleTextColor: "blue",
    // rowTitleColor: "blue",
    // rowContentColor: 'grey',
    // arrowColor: "red",
  };

  const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
  };


  return (
    <div className='home-main'>
      <a href="https://wa.me/8897035800"
        id="whatsapp-icon"
        target="_blank"
        title="Chat with us on WhatsApp">
        <img src={whatsAppImg} alt="WhatsApp Icon" id="whatsapp-img" />
      </a>
      {/* Home Hero Video */}
      {/* <Carousel controls={false} indicators={false}>
        <Carousel.Item>
          <video src={Home_Hero_One} autoPlay muted loop className="d-block w-100" />
        </Carousel.Item>
      </Carousel> */}
      {/* <Carousel controls={false} indicators={false}>
        <Carousel.Item>
          <video src={Home_Hero_Two} autoPlay muted loop className="d-block w-100" />
        </Carousel.Item>
      </Carousel> */}
      {/* Home Hero Video */}

      {/* Dubai Video Section */}
      <video src={Home_Hero_One} autoPlay loop muted className='video-full d-lg-block d-none'></video>
      <video src={Home_Hero_One_Tablet} autoPlay loop muted className='video-full d-md-block d-lg-none d-none '></video>
      <video src={Home_Hero_One_Mobile} autoPlay loop muted className='video-full d-block d-md-none '></video>
      {/* Dubai Video Section */}
      {/* Home About */}
      <div className='home-about container-fluid py-4'>
        <div className='home-about-inner container py-4'>
          <div className='row justify-content-center '>
            {/* Content on the Right Side */}
            <div className='col-lg-8 col-md-10 my-3 d-flex align-items-center justify-content-center '>
              <div className='home-about-content'>
                <h1 className='syne-title'>ABOU<span className='text-rose'>T</span> US<span className='text-rose'>.</span></h1>
                <h3 className='fw-bold mb-4 syne-title'><span className='text-rose'>Tattva Hi Life:</span> Hyderabad's Leading Real Estate PropTech, Advisory, Market Research & Analytics Firm.</h3>
                <p className='text-secondary'>It is a one-stop solution for all your real estate needs in Hyderabad. Founded in
                  2017, It has grown to become the largest and most trusted real estate
                  consultancy, market research, analytics and construction firm in the city.
                </p>
                <p className='text-secondary'>It offers a wide range of services, the company excels in various facets of the
                  real estate lifecycle, from initial land acquisition to post-sales activities.
                </p>
                <p className='text-secondary'>At the heart of Tattva Hi Life's operations lies its proficiency in Land
                  acquisition Conducting Market Analysis, Feasibility Studies, Project
                  Planning, Development Advisory, Marketing and Sales Strategy,
                  Investment and Valuation Advisory, Asset Management, Legal and
                  Regulatory Compliance - Providing data-driven insights and
                  recommendations based on the latest trends and technologies in the real estate
                  industry.
                </p>
                <p className='text-secondary'>It has a team of highly qualified and experienced professionals who have indepth knowledge and expertise in the property sector. We uses the best tools
                  and techniques to deliver high-quality and reliable services to its clients.
                </p>
                <p className='text-secondary'>Our vision is to be the ultimate destination for real estate solutions in
                  Hyderabad and beyond. Our mission is to create value for its clients by
                  providing them with innovative and customized solutions that meet their needs
                  and expectations.

                </p>
                <p className='text-secondary'>In essence, Tattva Hi Life's comprehensive suite of services and unwavering
                  commitment to excellence position it as a leader in the real estate prop-tech
                  landscape, shaping the future of urban living one development at a time.

                </p>
                <div className='home-about-btn text-start'>
                  <Link to="/about" className="text-rose"><FaLongArrowAltRight /> Read More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Home About */}
      {/* Dubai Video Section */}
      <video src={Home_Hero_Two} autoPlay loop muted className='video-full d-lg-block d-none'></video>
      <video src={Home_Hero_Two_Tablet} autoPlay loop muted className='video-full d-md-block d-lg-none d-none '></video>
      <video src={Home_Hero_Two_Mobile} autoPlay loop muted className='video-full d-block d-md-none '></video>
      {/* Dubai Video Section */}


      {/* Home Locations */}
      <div className='home-location container-fluid py-4 bg-light'>
        <div className='home-location-inner container py-4'>
          <h1 className='syne-title text-center text-uppercase'>Our Loca<span className='text-rose'>t</span>ions<span className='text-rose'>.</span></h1>
          <p className='mb-4 text-secondary'>At Tattva Hi Life, we pride ourselves on selecting prime locations that embody the perfect blend of accessibility, luxury, and potential for growth. Our real estate offerings span Hyderabad's most prestigious neighborhoods, each handpicked to deliver exceptional value to both investors and homeowners. Whether it's the heart of the city or serene suburban surroundings, our locations promise unmatched convenience, connectivity, and a lifestyle that stands above the rest.</p>
          <div className='home-location-imgs container-fluid'>
            {/* //// */}
            <div className='row'>
              <div className='col-lg-4 col-md-6 my-2'>
                <Link to='/location-hyderabad'>
                  <div className='loc-one rounded-15'>
                    <div className='loc-shadow rounded-15'>
                      <h4 className='syne-title'><span className="text-rose ">H</span>YDERABAD</h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6 my-2'>
                <Link to='/location-dubai'>
                  <div className='loc-three rounded-15'>
                    <div className='loc-shadow rounded-15'>
                      <h4 className='syne-title'><span className="text-rose ">D</span>UBAI</h4>
                    </div>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6 my-2'>
                <Link to='/location-banglore'>
                  <div className='loc-two rounded-15'>
                    <div className='loc-shadow rounded-15'>
                      <h4 className='syne-title'><span className="text-rose ">B</span>ANGLORE</h4>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            {/* //// */}
          </div>



        </div>
      </div>
      {/* Home Locations */}
      {/* Home Projects */}
      <div className='home-landowner container-fluid py-4'>
        <div className='home-landowner-inner container py-4'>
          <h1 className='syne-title text-center mb-3 text-uppercase'>Our Projec<span className='text-rose'>t</span>s<span className='text-rose'>.</span></h1>
          <p className='mb-4 text-secondary text-center'>Our Project Partnership offers property owners a unique chance to collaborate with Tattva Hi Life in lucrative real estate developments.</p>
          <div className='home-land-cards'>
            <div className='row'>
              {/* Hyderabad */}
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Trilight} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>The Trilight</h4>
                    <p className='mb-1'>Kokapet, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={IRISByRaghava} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>IRIS By Raghava</h4>
                    <p className='mb-1'>Raidurgam, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={DSRTheWorld} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>DSR - The World</h4>
                    <p className='mb-1'>Jubilee Hills, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={VamsiramMahanattan} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Vamsiram Manhattan</h4>
                    <p className='mb-1'>Khajaguda, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={EleganceImperaVilla} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Elegans imperia villa</h4>
                    <p className='mb-1'>Velmala, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={RajapushpaGreenDale} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Rajapushpa Green Dale villa</h4>
                    <p className='mb-1'>Tellapur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={RajapushpaSereneDale} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Rajapushpa Serene Dale villa</h4>
                    <p className='mb-1'>Tellapur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={HellamarkNature} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Hallmark - Nature's Nest Villas</h4>
                    <p className='mb-1'>Kollur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={HellamarkForest} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Hallmark - Floresta Villas</h4>
                    <p className='mb-1'>Patighanpur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={AikaaVilla} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Aikaa villas</h4>
                    <p className='mb-1'>Gandipet, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Dubai */}
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={DAMACHills} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>DAMAC Hills</h4>
                    <p className='mb-1'>Dubai</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={DAMACSuncity} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>DAMAC Suncity</h4>
                    <p className='mb-1'>Dubai</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={DanubeDiamondz} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Danube Diamondz</h4>
                    <p className='mb-1'>Dubai</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={DanubeOceanz} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Danube Oceanz</h4>
                    <p className='mb-1'>Dubai</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={EMAARMarinaCove} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>EMAAR - Marina Cove</h4>
                    <p className='mb-1'>Dubai</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={SobhaRealtyOrbis} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Sobha Realty - Orbis</h4>
                    <p className='mb-1'>Dubai</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={SobhaRealtySkyscapeAura} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Sobha Realty - Skyscape Aura</h4>
                    <p className='mb-1'>Dubai</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={SobhaRealty310RiversideCrescent} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Sobha Realty - 310 Riverside </h4>
                    <p className='mb-1'>Dubai</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={SobhaRealtyHartlandII} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Sobha Realty - Hartland II</h4>
                    <p className='mb-1'>Dubai</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={DAMACIslands} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>DAMAC - Islands</h4>
                    <p className='mb-1'>Dubai</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/trilight" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>

              {/* Old Projects */}
              {/*  <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Ten} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>ASBL Spire</h4>
                    <p className='mb-1'>Kokapet, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-asbl-spire" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Eleven} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>ASBL Spectra</h4>
                    <p className='mb-1'>Financial District</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-asbl-spectra" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_One} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>IRIS By Raghava</h4>
                    <p className='mb-1'>Raidugam, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-iris" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Two} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Wave By Raghava</h4>
                    <p className='mb-1'>Tellapur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-wave" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Three} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Pearl By Auro Reality</h4>
                    <p className='mb-1'>Hi-Tech City, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-pearl" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Four} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Rajpushpa Pristinia</h4>
                    <p className='mb-1'>Kokapet, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-rajpushpa" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Five} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>My Home Apas</h4>
                    <p className='mb-1'>Kokapet, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-my-home" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Six} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Upcoming Luxury Apartments</h4>
                    <p className='mb-1'>Neopolis, Kokapet</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-upcoming" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Seven} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Upcoming Luxury Apartments</h4>
                    <p className='mb-1'>Narsingi, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-upcoming" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Eight} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Candeur Lakescape</h4>
                    <p className='mb-1'>Kondapur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-candeur" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Nine} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Luxury Apartments</h4>
                    <p className='mb-1'>Kondapur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-luxury" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* Home Projects */}
      <div className='home-project container-fluid py-4 bg-light'>
        <div className='container py-4'>
          <div className='row'>

            {/* Project List with Hover Effect */}
            <div className='project-list mt-4 pb-5'>
              {/* Project 1 */}
              <div className="project-item py-3 position-relative my-2">
                <Link to="/trilight">
                  <div className="row align-items-center">
                    <div className="col-4 text-start">
                      <h6 className="mb-0">The Trilight</h6>
                    </div>
                    <div className="col-1">
                      <span>2023</span>
                    </div>
                    <div className="col-6">
                      <h5 className="text-center project-name">The Trilight</h5>
                      <div className="project-hover-content position-absolute">
                        <img
                          src={Trilight}
                          alt="The Trilight Project"
                          className="hover-image"
                        />
                        <div className="hover-info text-center">
                          <p className="mb-0 fs-7">Kokapet, Hyderabad</p>
                        </div>
                      </div>
                    </div>

                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Project 2 */}
              <div className="project-item py-3 position-relative my-2">
                <Link to="/irisbyraghava">
                  <div className="row align-items-center">
                    <div className="col-4 text-start">
                      <h6 className="mb-0">IRIS By Raghava</h6>
                    </div>
                    <div className="col-1">
                      <span>2023</span>
                    </div>
                    <div className="col-6">
                      <h5 className="text-center project-name">IRIS By Raghava</h5>
                      <div className="project-hover-content position-absolute">
                        <img
                          src={IRISByRaghava}
                          alt="IRIS By Raghava Project"
                          className="hover-image"
                        />
                        <div className="hover-info text-center">
                          <p className="mb-0 fs-7">Raidurgam, Hyderabad</p>
                        </div>
                      </div>
                    </div>

                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Project 3 */}
              <div className="project-item py-3 position-relative my-2">
                <Link to="/dsrtheworld">
                  <div className="row align-items-center">
                    <div className="col-4 text-start">
                      <h6 className="mb-0">DSR - The World</h6>
                    </div>
                    <div className="col-1">
                      <span>2023</span>
                    </div>
                    <div className="col-6">
                      <h5 className="text-center project-name">DSR - The World</h5>
                      <div className="project-hover-content position-absolute">
                        <img
                          src={DSRTheWorld}
                          alt="DSR - The World Project"
                          className="hover-image"
                        />
                        <div className="hover-info text-center">
                          <p className="mb-0 fs-7">Jubilee Hills, Hyderabad</p>
                        </div>
                      </div>
                    </div>

                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Project 4 */}
              <div className="project-item py-3 position-relative my-2">
                <Link to="/vamsirammahanattan">
                  <div className="row align-items-center">
                    <div className="col-4 text-start">
                      <h6 className="mb-0">Vamsiram Manhattan</h6>
                    </div>
                    <div className="col-1">
                      <span>2023</span>
                    </div>
                    <div className="col-6">
                      <h5 className="text-center project-name">Vamsiram Manhattan</h5>
                      <div className="project-hover-content position-absolute">
                        <img
                          src={VamsiramMahanattan}
                          alt="Vamsiram Manhattan Project"
                          className="hover-image"
                        />
                        <div className="hover-info text-center">
                          <p className="mb-0 fs-7">Khajaguda, Hyderabad</p>
                        </div>
                      </div>
                    </div>

                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Project 5 */}
              <div className="project-item py-3 position-relative my-2">
                <Link to="/eleganceimperavilla">
                  <div className="row align-items-center">
                    <div className="col-4 text-start">
                      <h6 className="mb-0">Elegance Imperia Villa</h6>
                    </div>
                    <div className="col-1">
                      <span>2023</span>
                    </div>
                    <div className="col-6">
                      <h5 className="text-center project-name">Elegance Imperia Villa</h5>
                      <div className="project-hover-content position-absolute">
                        <img
                          src={EleganceImperaVilla}
                          alt="Elegance Imperia Villa Project"
                          className="hover-image"
                        />
                        <div className="hover-info text-center">
                          <p className="mb-0 fs-7">Velmala, Hyderabad</p>
                        </div>
                      </div>
                    </div>

                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Project 6 */}
              <div className="project-item py-3 position-relative my-2">
                <Link to="/project-sobha-realty-310-riverside">
                  <div className="row align-items-center">
                    <div className="col-4 text-start">
                      <h6 className="mb-0">Sobha Realty - 310 Riverside</h6>
                    </div>
                    <div className="col-1">
                      <span>2023</span>
                    </div>
                    <div className="col-6">
                      <h5 className="text-center project-name">Sobha Realty - 310 Riverside</h5>
                      <div className="project-hover-content position-absolute">
                        <img
                          src={SobhaRealty310RiversideCrescent}
                          alt="Sobha Realty 310 Riverside Project"
                          className="hover-image"
                        />
                        <div className="hover-info text-center">
                          <p className="mb-0 fs-7">Dubai</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-damac-hills'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className='mb-0'>DAMAC Hills</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>DAMAC Hills</h5>
                      <div className='project-hover-content position-absolute'>
                        <img src={DAMACHills} alt='DAMAC Hills Project' className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Dubai</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-damac-suncity'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className='mb-0'>DAMAC Suncity</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>DAMAC Suncity</h5>
                      <div className='project-hover-content position-absolute'>
                        <img src={DAMACSuncity} alt='DAMAC Suncity Project' className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Dubai</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-danube-diamondz'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className='mb-0'>Danube Diamondz</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>Danube Diamondz</h5>
                      <div className='project-hover-content position-absolute'>
                        <img src={DanubeDiamondz} alt='Danube Diamondz Project' className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Dubai</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-danube-oceanz'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className='mb-0'>Danube Oceanz</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>Danube Oceanz</h5>
                      <div className='project-hover-content position-absolute'>
                        <img src={DanubeOceanz} alt='Danube Oceanz Project' className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Dubai</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-emaar-marina-cove'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className='mb-0'>EMAAR - Marina Cove</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>EMAAR - Marina Cove</h5>
                      <div className='project-hover-content position-absolute'>
                        <img src={EMAARMarinaCove} alt='EMAAR Marina Cove Project' className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Dubai</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              <div className='project-item py-3 position-relative my-2'>
                <Link to='/project-sobha-realty-orbis'>
                  <div className='row align-items-center'>
                    <div className='col-4 text-start'>
                      <h6 className='mb-0'>Sobha Realty - Orbis</h6>
                    </div>
                    <div className='col-1'>
                      <span className=''>2023</span>
                    </div>
                    <div className='col-6'>
                      <h5 className='text-center project-name'>Sobha Realty - Orbis</h5>
                      <div className='project-hover-content position-absolute'>
                        <img src={SobhaRealtyOrbis} alt='Sobha Realty Orbis Project' className='hover-image' />
                        <div className='hover-info text-center'>
                          <p className='mb-0 fs-7'>Dubai</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-1 project-arrow'>
                      <i className='fa-solid fa-arrow-right'></i>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Additional project items can follow the same structure */}
              <div className='home-about-btn text-center mt-4'>
                <Link to="/projects" className="text-rose"><FaLongArrowAltRight /> View All</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Home Projects*/}
      {/* Home Testimonials */}
      <div className='home-founders container-fluid py-4'>
        <div className='home-founders container py-4'>
          <div className='row mb-4 container'>
            <div className='col-lg-8 text-start'>
              <h1 className='syne-title'>WHA<span className='text-rose'>T</span><br /> OUR CLIEN<span className='text-rose'>T</span>S SAY<span className='text-rose'>!</span></h1>
            </div>
          </div>
          <Slider
            dots={false}
            infinite={true}
            speed={500}
            slidesToShow={2}
            slidesToScroll={1}
            arrows={true}
            autoplay={true} // Enable auto sliding
            autoplaySpeed={3000} // Set the duration for each slide in milliseconds
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            {/* Testi one */}
            <div className='home-testi row d-flex justify-content-center'>
              <div className='home-testi-inner col-md-10'>
                <div className='testi-one-inner-top d-flex align-items-end'>
                  <img src={Quote_Icon} className="w-4 me-2" />
                  <p className='fw-bold mb-0'>Seamless and Rewarding Property Investment </p>
                </div>
                <p className='text-secondary text-start my-2'>" Tattva Hi Life stands out as a real estate partner that truly understands the market dynamics. Their data-driven approach and professional advisory services made my property investment seamless and highly rewarding. They’re not just consultants; they’re visionaries committed to helping clients make informed decisions. I highly recommend them for anyone looking for reliable and insightful real estate services. "</p>
                <img src={Testi_Line} className='img-fluid mt-3' />
                <div className='row'>
                  <div className='col-2'></div>
                  <div className='col-10 d-flex align-items-center testi-one-inner-bottom'>
                    <img src={Testi_One} className='w-15 rounded-circle' />
                    <div className='text-start ms-3'>
                      <p className='fw-bold mb-1'> Ravi Varma, Hyderabad</p>
                      <div className='test-rating'>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Testi one */}
            <div className='home-testi row d-flex justify-content-center'>
              <div className='home-testi-inner col-md-10'>
                <div className='testi-one-inner-top d-flex align-items-end'>
                  <img src={Quote_Icon} className="w-4 me-2" />
                  <p className='fw-bold mb-0'>Unparalleled Clarity and Trust in Real Estate Advisory</p>
                </div>
                <p className='text-secondary text-start my-2'>" In an industry often marred by ambiguity, Tattva Hi Life brings unparalleled clarity and trust. Their PropTech solutions and deep market research helped me identify the perfect investment opportunity. Their team is proactive, transparent, and highly knowledgeable. If you’re looking for real estate expertise in Hyderabad, Tattva Hi Life is the name to trust. "</p>
                <img src={Testi_Line} className='img-fluid mt-3' />
                <div className='row'>
                  <div className='col-2'></div>
                  <div className='col-10 d-flex align-items-center testi-one-inner-bottom'>
                    <img src={Testi_Two} className='w-15 rounded-circle' />
                    <div className='text-start ms-3'>
                      <p className='fw-bold mb-1'>Sushmita Reddy, Secunderabad</p>
                      <div className='test-rating'>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                        <i class="fas fa-star text-rose"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      {/* Home Testimonials */}
      {/* Home FAQ'S */}
      <div className='container-fluid faq py-4'>
        <div className='container py-4'>
          <h1 className='syne-title text-center mb-4'>FAQ'S<span className='text-rose'>.</span></h1>
          <div className='row d-flex justify-content-center'>
            <div className='col-lg-9'>
              <Faq
                data={data}
                styles={styles}
                config={config}
              />
            </div>
          </div>

        </div>
      </div>
      {/* Home FAQ'S */}
      {/* Home Discuss*/}
      <div className='container-fluid py-5 home-discuss'>
        <div className='container py-5 home-discuss-inner bg-light p-4 rounded-15 shadow-sm'>
          <h1 className='syne-title mb-4 text-start'>
            LET'S DISCUSS<br />YOUR IDEA<span className='text-rose'>.</span>
          </h1>
          <div className='discuss-inner'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='discuss-left text-start'>
                  <p className='text-secondary'>
                    We’re excited to hear your vision! Whether you have a concept or need help brainstorming, our team is ready to collaborate. Let’s connect and turn your idea into reality—schedule a meeting with us today!
                  </p>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-envelope me-2'></i>
                    <p className='mb-0 text-secondary'>rakesh.tattvahilife@gmail.com</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-mobile-alt me-2'></i>
                    <p className='mb-0 text-secondary'>+91 88970 35800</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-map-marker-alt me-2'></i>
                    <p className='mb-0 text-secondary'>10, Prashanthi Hills, Khajaguda, Gachibowli, Hyderabad</p>
                  </div>
                  <div className='discuss-icons d-flex'>
                    <a href='https://www.linkedin.com/in/tattva-hi-life-735094319/' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-white'></i></a>
                    <a href='https://x.com/TattvaHiLife' className='text-decoration-none'><i className='fab fa-twitter text-white'></i></a>
                    <a href='https://www.facebook.com/profile.php?id=100086552704523' className='text-decoration-none'><i className='fab fa-facebook-f text-white'></i></a>
                    <a href='https://www.instagram.com/tattvahilife_international/' className='text-decoration-none'><i className='fab fa-instagram text-white'></i></a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <form action="https://api.web3forms.com/submit" method="POST">
                  <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                  <input
                    type='text'
                    placeholder='Name'
                    name="name"
                    className='form-control my-2'
                    required

                  />
                  <input
                    type='text'
                    placeholder='Mobile'
                    name="mobile"
                    className='form-control my-2'
                    required
                  />
                  <input
                    type='email'
                    placeholder='Email'
                    name="email"
                    className='form-control my-2'
                    required
                  />
                  <textarea
                    placeholder='Message'
                    name="message"
                    rows='4'
                    className='form-control my-2'
                    required
                  />
                  <div className='text-start mt-3 d-flex align-items-center'>
                    <span className='fs-2 text-rose'>→</span>
                    <input type='submit' value='Send' className='border-0 text-rose discuss-btn' />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Home Discuss*/}
      {/* Brand Slider */}

      {/* Brand Slider */}


    </div>
  )
}

export default Home