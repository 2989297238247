// src/pages/Contact.js
import React from 'react';
import { useEffect, useState } from 'react';
import './Contact.css'


const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on page load
}, []);
  // State to manage the theme mode (light/dark)
  const [theme, setTheme] = useState('light'); // Default theme

  useEffect(() => {
    // Check for stored theme in localStorage, and apply it.
    const currentTheme = localStorage.getItem('theme') || 'light';
    setTheme(currentTheme);
  }, []);


  return (
    <div className="contact-main">
      {/* Contact Hero */}
      <div className='contact-hero'>
        <div className='about-hero-inner d-flex justify-content-center align-items-center'>
          <div>
            <h1 className='syne-title'>CONTACT US<span className='text-rose'>.</span></h1>
            <p className='text-center'>Home / <span className='text-rose'>Contact Us</span></p>
          </div>
        </div>
      </div>
      {/* Contact Hero */}
      {/* Contact Discuss */}
      <div className='container-fluid py-5 contact-discuss '>
        <div className='container py-5  contact-discuss-inner'>
          <div className='discuss-inner'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='discuss-left text-start'>
                  <p className='text-secondary'>
                    We’re excited to hear your vision! Whether you have a concept or need help brainstorming, our team is ready to collaborate. Let’s connect and turn your idea into reality—schedule a meeting with us today!
                  </p>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-envelope me-2'></i>
                    <p className='mb-0'>rakesh.tattvahilife@gmail.com</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-mobile-alt me-2'></i>
                    <p className='mb-0'>+91 88970 35800</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-map-marker-alt me-2'></i>
                    <p className='mb-0'>10, Prashanthi Hills, Khajaguda, Gachibowli, Hyderabad</p>
                  </div>
                  <div className='discuss-icons d-flex'>
                    <a href='https://www.linkedin.com/in/tattva-hi-life-735094319/' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-white'></i></a>
                    <a href='https://x.com/TattvaHiLife' className='text-decoration-none'><i className='fab fa-twitter text-white'></i></a>
                    <a href='https://www.facebook.com/profile.php?id=100086552704523' className='text-decoration-none'><i className='fab fa-facebook-f text-white'></i></a>
                    <a href='https://www.instagram.com/tattvahilife_international/' className='text-decoration-none'><i className='fab fa-instagram text-white'></i></a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <form action="https://api.web3forms.com/submit" method="POST">
                  <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                  <input
                    type='text'
                    placeholder='Name'
                    name="name"
                    className='form-control my-2' required
                  />
                  <input
                    type='text'
                    placeholder='Mobile'
                    name="mobile"
                    className='form-control my-2' required
                  />
                  <input
                    type='email'
                    placeholder='Email'
                    name="email"
                    className='form-control my-2' required
                  />
                  <textarea
                    placeholder='Message'
                    name="message"
                    rows='4'
                    className='form-control my-2' required
                  />
                  <div className='text-start mt-3 d-flex align-items-center'>
                    <span className='fs-2 text-rose'>→</span>
                    <input type='submit' value='Send' className='border-0 text-rose discuss-btn' />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Contact Discuss */}
      {/* Contact Map */}
      <div className='contact-map'>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15227.359638663174!2d78.3797368!3d17.4194688!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb94004ceeac4d%3A0x9a01c66647002fcf!2sprasanti%20hills%20colony!5e0!3m2!1sen!2sin!4v1729155500487!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      {/* Contact Map */}
    </div>
  );
};

export default Contact;
