import React from 'react';
import { Link } from 'react-router-dom';  // For routing to project details page
import { FaLongArrowAltRight } from 'react-icons/fa';  // For the "Know More" arrow icon
import './Projects.css'
import './Home.css'

// Import the JSON data (assuming the file is in the same directory or the correct path)
// import projects from './projects.json';  // Adjust the path if needed
import projects from '../data/ProjectsData';
import { useEffect } from 'react';

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on page load
  }, []);


  return (
    <div className='projects-main'>
      {/* WAVE Hero */}
      <div className='about-hero'>
        <div className='about-hero-inner d-flex justify-content-center align-items-center'>
          <div>
            <h1 className='syne-title'>PROJECTS<span className='text-rose'>.</span></h1>
            <p className='text-center'>Home / <span className='text-rose'>Projects</span></p>
          </div>
        </div>
      </div>
      {/* WAVE Hero */}
      {/* Project cards */}
      <div className='container-fluid py-4'>
        <div className='container py-4'>
          <div className="row">
            {projects.map((project) => (
              <div className="col-lg-4 col-md-6 my-3" key={project.id}>
                <div className="home-land-card h-100 shadow-sm">
                  <img src={project.image} className="img-fluid home-land-card-img" alt={project.title} />
                  {/* <h2>{project.image}</h2> */}
                  <div className="home-land-card-content p-3 text-center bg-white">
                    <h6 className="fw-bold">{project.title}</h6>
                    <p className="mb-1">{project.location}</p>
                    <div className="home-about-btn text-center">
                      <Link to={`/projects/${project.id}`} className="text-rose">
                        <FaLongArrowAltRight /> Know More
                      </Link>

                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Project cards */}
      {/* Home Discuss*/}
      <div className='container-fluid py-5 home-discuss'>
        <div className='container py-5 home-discuss-inner bg-light p-4 rounded-15 shadow-sm'>
          <h1 className='syne-title mb-4 text-start'>
            LET'S DISCUSS<br />YOUR IDEA<span className='text-rose'>.</span>
          </h1>
          <div className='discuss-inner'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='discuss-left text-start'>
                  <p className='text-secondary'>
                    We’re excited to hear your vision! Whether you have a concept or need help brainstorming, our team is ready to collaborate. Let’s connect and turn your idea into reality—schedule a meeting with us today!
                  </p>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-envelope me-2'></i>
                    <p className='mb-0 text-secondary'>rakesh.tattvahilife@gmail.com</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-mobile-alt me-2'></i>
                    <p className='mb-0 text-secondary'>+91 88970 35800</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-map-marker-alt me-2'></i>
                    <p className='mb-0 text-secondary'>10, Prashanthi Hills, Khajaguda, Gachibowli, Hyderabad</p>
                  </div>
                  <div className='discuss-icons d-flex'>
                    <a href='https://www.linkedin.com/in/tattva-hi-life-735094319/' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-white'></i></a>
                    <a href='https://x.com/TattvaHiLife' className='text-decoration-none'><i className='fab fa-twitter text-white'></i></a>
                    <a href='https://www.facebook.com/profile.php?id=100086552704523' className='text-decoration-none'><i className='fab fa-facebook-f text-white'></i></a>
                    <a href='https://www.instagram.com/tattvahilife_international/' className='text-decoration-none'><i className='fab fa-instagram text-white'></i></a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <form action="https://api.web3forms.com/submit" method="POST">
                  <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                  <input
                    type='text'
                    placeholder='Name'
                    name="name"
                    className='form-control my-2'
                    required

                  />
                  <input
                    type='text'
                    placeholder='Mobile'
                    name="mobile"
                    className='form-control my-2'
                    required
                  />
                  <input
                    type='email'
                    placeholder='Email'
                    name="email"
                    className='form-control my-2'
                    required
                  />
                  <textarea
                    placeholder='Message'
                    name="message"
                    rows='4'
                    className='form-control my-2'
                    required
                  />
                  <div className='text-start mt-3 d-flex align-items-center'>
                    <span className='fs-2 text-rose'>→</span>
                    <input type='submit' value='Send' className='border-0 text-rose discuss-btn' />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Home Discuss*/}
    </div>

  );
};

export default Projects;
