import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaPhone, FaEnvelope, FaLinkedinIn, FaFacebookF, FaInstagram, FaXTwitter } from "react-icons/fa6";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import './Header.css';
import Header_Logo from '../images/tattva-logo-png.png';
import Popup_Img from '../images/popup-img.png';
import linkedin from '../images/linkedin.png';
import facebook from '../images/facebook.png';
import twitter from '../images/twitter.png';
import instagram from '../images/instagram.png'


function Header() {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleShowModal();
        }, 3000);

        return () => clearTimeout(timer); // Clean up the timer
    }, []);



    return (
        <header>
            {/* Top Section with Email and Phone */}
            <div className="header-top py-2 container-fluid d-flex justify-content-center bg-dark">
                <div className="container d-flex align-items-center row">
                    <div className="d-flex justify-content-around col-lg-10 col-md-8 col-4">
                        <a href="mailto:rakesh.tattvahilife@gmail.com" className="header-contact text-light d-flex align-items-center">
                            <FaEnvelope className="me-2" />
                            <span className="d-md-block d-none">rakesh.tattvahilife@gmail.com</span>
                        </a>
                        <a href="tel:+918897035800" className="header-phone text-light d-flex align-items-center">
                            <FaPhone className="me-2" />
                            <span className="d-md-block d-none">+91 88970 35800</span>
                        </a>
                    </div>
                    {/* Social Icons and Enquiry */}
                    <div className="col-lg-2 col-md-4 col-md col-8 d-flex justify-content-md-end justify-content-end my-md-0 my-2">
                        <div className="header-social d-flex align-items-center">
                            <a href="https://www.linkedin.com/in/tattva-hi-life-735094319/" target="_blank" className=""><img src={linkedin} className="w-50"/></a>
                            <a href="https://x.com/TattvaHiLife" target="_blank" className="text-light me-"><img src={twitter} className="w-50"/></a>
                            <a href="https://www.facebook.com/profile.php?id=100086552704523" target="_blank" className="text-light me-"><img src={facebook} className="w-50"/></a>
                            <a href="https://www.instagram.com/tattvahilife_international/" target="_blank" className="text-light me-"><img src={instagram} className="w-50"/></a>
                        </div>
                    </div>
                    {/* <div className="col-md-4 col-8 d-flex justify-content-md-end justify-content-center my-md-0 my-2">
                        <div className="header-social d-flex align-items-center">
                            <a href="https://www.linkedin.com/in/tattva-hi-life-735094319/" target="_blank" className="text-light me-3"><FaLinkedinIn /></a>
                            <a href="https://x.com/TattvaHiLife" target="_blank" className="text-light me-3"><FaXTwitter /></a>
                            <a href="https://www.facebook.com/profile.php?id=100086552704523" target="_blank" className="text-light me-3"><FaFacebookF /></a>
                            <a href="https://www.instagram.com/tattvahilife_international/" target="_blank" className="text-light me-3"><FaInstagram /></a>
                        </div>
                    </div> */}
                </div>
            </div>

            {/* Middle Section with Logo and Navbar */}
            <div className="header-middle bg-black text-light py-1">
                <div className="container d-flex justify-content-between align-items-center">
                    {/* Logo */}
                    <Link to="/" className="header-logo">
                        <img src={Header_Logo} alt="Tattva Logo" className="img-fluid rounded-circle" />
                    </Link>

                    {/* Navbar */}
                    <nav className="navbar navbar-expand-lg navbar-dark">
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/" exact>Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/landowner-share">Landowner Share</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/about">About Us</NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink
                                        className="nav-link dropdown-toggle"
                                        to="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Locations
                                    </NavLink>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><NavLink className="dropdown-item" to="/location-hyderabad">Hyderabad</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/location-dubai">Dubai</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/location-banglore">Banglore</NavLink></li>
                                    </ul>
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <NavLink
                                        className="nav-link dropdown-toggle"
                                        to="#"
                                        id="projectsDropdown"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        Projects
                                    </NavLink>
                                    <ul className="dropdown-menu" aria-labelledby="projectsDropdown">
                                        <li><NavLink className="dropdown-item" to="/project-asbl-spire">ASBL Spire</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/project-asbl-spectra">ASBL Spectra</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/project-iris">IRIS By Raghava</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/project-wave">Wave By Raghava</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/project-pearl">Peral By Auro Reality</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/project-rajpushpa">Rajpushpa Pristinia</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/project-my-home">My Home Apas</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/project-upcoming">Upcoming Luxury Apartments</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/project-candeur">Candeur landscape</NavLink></li>
                                        <li><NavLink className="dropdown-item" to="/project-luxury">Luxury Apartments</NavLink></li>
                                    </ul>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/projects">Projects</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/contact-us">Contact Us</NavLink>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    {/* Enquiry Button */}
                    <div className="enquiry-btn d-lg-block d-none">
                        <button className="btn enquire-btn text-rose" onClick={handleShowModal}>
                            <span className='fs-3'>→</span> Enquire Now
                        </button>
                    </div>
                </div>
            </div>

            {/* Modal Code */}
            {/* Modal Code */}
            {showModal && (
                <div className={`modal fade show ${showModal ? 'fade' : ''}`} style={{ display: 'block' }}>
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body row p-4 flex-wrap-reverse">
                                {/* Left section with image and number */}
                                <div className="col-lg-4 d-flex align-items-center justify-content-center">
                                    <div>
                                        <div className="d-flex justify-content-center mb-4">
                                            <img
                                                src={Popup_Img}
                                                alt="Support"
                                                className="rounded-circle me-3"
                                                style={{ width: '100px', height: '100px' }}
                                            />
                                        </div>
                                        <div className="text-center">
                                            <h3 className="text-rose">+91 988970 35800</h3>
                                            <p className="text-secondary">Make a call, Get Insured</p>
                                        </div>
                                    </div>
                                </div>

                                {/* Divider */}
                                <div className="divider col-lg-1">
                                    <div className="vertical-line"></div>
                                </div>

                                {/* Right section with form */}
                                <div className="col-lg-7 position-relative d-flex justify-content-center align-items-center">
                                    <div className="text-end">
                                        <div className="pop-cancel position-absolute end-0 top-0" onClick={handleCloseModal}>
                                            <i className="fas fa-times text-rose fs-3"></i>
                                        </div>
                                    </div>
                                    <div className="pop-up-right w-100">
                                        <form action="https://api.web3forms.com/submit" method="POST">
                                            <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                                            <h4 className="mb-4 text-rose">Enquire Now</h4>

                                            <div className="mb-3">
                                                <input type="text" name="name" className="form-control" placeholder="Enter your Name"  required/>
                                            </div>
                                            <div className="mb-3">
                                                <input type="text" name="mobile" className="form-control" placeholder="Enter your Phone Number"  required/>
                                            </div>
                                            <div className="mb-3">
                                                <input type="email" name="email" className="form-control" placeholder="Enter your Email"  required/>
                                            </div>
                                            <div className="mb-3">
                                                <textarea className="form-control" name="message" rows="3" placeholder="Type your message here" required></textarea>
                                            </div>
                                            <button className="btn enquire-btn text-rose d-flex align-items-center" type="submit">
                                                <span className="fs-3 me-2">→</span> Submit
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </header>
    );
}

export default Header;
