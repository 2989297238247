import React from 'react'
import './LandownerShare.css'
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import Landowner_About_One from '../images/landowner-about-one.png'
import Home_Project_One from '../images/home-project-one-1.jpg';
import Home_Project_Two from '../images/home-project-two.jpg';
import Home_Project_Three from '../images/home-project-three.jpg';
import Home_Project_Four from '../images/home-project-four.jpg';
import Home_Project_Five from '../images/home-project-five.jpg';
import Home_Project_Six from '../images/home-project-six.jpg';
import Home_Project_Seven from '../images/home-project-seven.jpg';
import Home_Project_Eight from '../images/home-project-eight.jpg';
import Home_Project_Nine from '../images/home-project-nine.jpeg';
import Home_Project_Ten from '../images/asbl-project.jpg';
import Home_Project_Eleven from '../images/asbl-spectra.jpg';
import { useEffect } from 'react';

function LandownerShare() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on page load
  }, []);
  return (
    <div className='landowner-main'>
      {/* Landowner Hero */}
      <div className='landowner-hero d-flex justify-content-center align-items-center'>
        <h1 className='syne-title text-white'><span className='text-rose'>L</span>ANDOWNER <span className='text-rose'>S</span>HARE<span className='text-rose'>.</span></h1>
      </div>
      {/* Landowner Hero */}
      {/* Landowner About */}
      <div className='landowner-about container-fluid py-4'>
        <div className='landowner-about-inner container py-4'>
          <h4 className='syne-title text-center mb-4'>Unlocking the Future of Real Estate : <br /> <span className='text-rose'>Land Owner Shares Apartments</span></h4>
          <div className='row'>
            <div className='col-lg-6 my-2'>
              <img src={Landowner_About_One} className='img-fluid shadow-sm rounded' />
            </div>
            <div className='col-lg-6 my-2 p-3 d-flex justify-content-center align-items-center'>
              <div className='landowner-about-one-right text-secondary'>
                <p><span className='text-rose fw-bold'>Land Owner Shares</span> (LOS) represent a modern and innovative approach to real estate investment that offers unique opportunities for landowners, builders, and potential buyers alike.</p>
                <p>In the initial stages of a development project, a development agreement is typically executed between the landowner and the builder, which grants the builder the rights to utilize the land for the construction and development of the project. In exchange, the landowner is awarded ownership of a certain number of apartments or units as part of the agreement. This creates a mutually beneficial relationship, where the landowner becomes a key stakeholder in the project’s success.</p>
                <p>Once the project progresses, landowners often opt to sell these apartments to potential buyers, frequently offering them at discounted prices for those who choose outright payment options. This presents a valuable opportunity for investors looking for a reduced-cost entry into the real estate market while enabling the landowner to capitalize on their share in the project.</p>
              </div>
            </div>
            {/* <div className='col-lg-6 my-2 p-3 d-flex justify-content-center align-items-center'>
              <div className='landowner-about-one-right'>
                <p>
                In the dynamic world of real estate investment, innovative opportunities are constantly emerging to cater to the diverse needs of investors. One such approach gaining traction is the concept of Land Owner Shares (LOS) and the associated benefits of investing in apartments that offer these shares.</p>
                <p>Land Owner Shares provide an exciting and accessible avenue for real estate investment, making it easier for investors to participate in property ownership through this modern strategy.</p>
              </div>
            </div>
            <div className='col-lg-6 my-2'>
              <img src={Landowner_About_Two} className='img-fluid shadow-sm rounded' />
            </div> */}
          </div>
        </div>
      </div>
      {/* Landowner About */}
      {/* Landowner Benefits */}
      <div className='landowner-benefit container-fluid py-4 bg-light'>
        <div className='landowner-benefit-inner container py-4'>
          <div className='row'>
            <div className='col-lg-6'>
              <h4 className='syne-title text-md-start mb-4'>The Benefits of Buying <br /> <span className='text-rose'>Land Owner Shares Apartments</span></h4>
            </div>
            <div className='col-lg-6'>
              <p className='text-secondary'>Land Owner Shares apartments offer a unique investment opportunity, providing affordability and ownership benefits, making property investment more accessible and rewarding.</p>
            </div>
          </div>

          <div className="container my-5">
            <div className="row g-4">

              {/* <!-- Card 1: Landowner's Share of Flats & Villas --> */}
              <div className="col-lg-4 col-md-6">
                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                  <div className="card-icon mb-3">
                    <i className="fas fa-home fa-3x text-rose"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Landowner's Share of Flats & Villas</h5>
                    <p className="text-secondary">Units from the landowner's share are often among the best, offering premium layouts, views, and locations within the property.</p>
                  </div>
                </div>
              </div>

              {/* <!-- Card 2: Discounted Price --> */}
              <div className="col-lg-4 col-md-6">
                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                  <div className="card-icon mb-3">
                    <i className="fas fa-tags fa-3x text-rose"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Discounted Price</h5>
                    <p className="text-secondary">Landowner shares are typically sold at a discounted rate, providing a great opportunity to own premium property at a lower cost.</p>
                  </div>
                </div>
              </div>

              {/* <!-- Card 3: Better Availability --> */}
              <div className="col-lg-4 col-md-6">
                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                  <div className="card-icon mb-3">
                    <i className="fas fa-calendar-check fa-3x text-rose"></i>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Better Availability</h5>
                    <p className="text-secondary">With landowner apartments, you have a wider selection and better availability, giving you more choices in selecting your dream home.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      {/* Landowner Benefits */}
      {/* Home Projects */}
      <div className='home-landowner container-fluid py-4'>
        <div className='home-landowner-inner container py-4'>
          <h1 className='syne-title text-center mb-3'>Our Projects<span className='text-rose'>.</span></h1>
          <p className='mb-4 text-secondary'>Our Project Partnership offers property owners a unique chance to collaborate with Tattva Hi Life in lucrative real estate developments. By partnering with us, owners can leverage their land to secure a substantial share in project profits without the complexities of construction management. With our expert guidance and commitment to transparency, we help property owners maximize their returns while they retain control over their valuable assets.</p>
          <div className='home-land-cards'>
            <div className='row'>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Ten} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>ASBL Spire</h4>
                    <p className='mb-1'>Kokapet, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-asbl-spire" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Eleven} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>ASBL Spectra</h4>
                    <p className='mb-1'>Financial District</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-asbl-spectra" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_One} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>IRIS By Raghava</h4>
                    <p className='mb-1'>Raidugam, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-iris" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Two} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Wave By Raghava</h4>
                    <p className='mb-1'>Tellapur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-wave" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Three} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Pearl By Auro Reality</h4>
                    <p className='mb-1'>Hi-Tech City, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-pearl" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Four} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Rajpushpa Pristinia</h4>
                    <p className='mb-1'>Kokapet, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-rajpushpa" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Five} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>My Home Apas</h4>
                    <p className='mb-1'>Kokapet, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-my-home" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Six} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Upcoming Luxury Apartments</h4>
                    <p className='mb-1'>Neopolis, Kokapet</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-upcoming" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Seven} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Upcoming Luxury Apartments</h4>
                    <p className='mb-1'>Narsingi, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-upcoming" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Eight} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Candeur Lakescape</h4>
                    <p className='mb-1'>Kondapur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-candeur" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 my-3'>
                <div className='home-land-card h-100 shadow-sm'>
                  <img src={Home_Project_Nine} className='img-fluid home-land-card-img' />
                  <div className='home-land-card-content p-3 text-center bg-white'>
                    <h4 className='fw-bold'>Luxury Apartments</h4>
                    <p className='mb-1'>Kondapur, Hyderabad</p>
                    <div className='home-about-btn text-center'>
                      <Link to="/project-luxury" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Home Projects */}
      {/* Home Discuss*/}
      <div className='container-fluid py-5 home-discuss'>
        <div className='container py-5 home-discuss-inner bg-light p-4 rounded-15 shadow-sm'>
          <h1 className='syne-title mb-4 text-start'>
            LET'S DISCUSS<br />YOUR IDEA<span className='text-rose'>.</span>
          </h1>
          <div className='discuss-inner'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='discuss-left text-start'>
                  <p className='text-secondary'>
                    We’re excited to hear your vision! Whether you have a concept or need help brainstorming, our team is ready to collaborate. Let’s connect and turn your idea into reality—schedule a meeting with us today!
                  </p>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-envelope me-2'></i>
                    <p className='mb-0 text-secondary'>rakesh.tattvahilife@gmail.com</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-mobile-alt me-2'></i>
                    <p className='mb-0 text-secondary'>+91 88970 35800</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-map-marker-alt me-2'></i>
                    <p className='mb-0 text-secondary'>10, Prashanthi Hills, Khajaguda, Gachibowli, Hyderabad</p>
                  </div>
                  <div className='discuss-icons d-flex'>
                    <a href='https://www.linkedin.com/in/tattva-hi-life-735094319/' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-white'></i></a>
                    <a href='https://x.com/TattvaHiLife' className='text-decoration-none'><i className='fab fa-twitter text-white'></i></a>
                    <a href='https://www.facebook.com/profile.php?id=100086552704523' className='text-decoration-none'><i className='fab fa-facebook-f text-white'></i></a>
                    <a href='https://www.instagram.com/tattvahilife_international/' className='text-decoration-none'><i className='fab fa-instagram text-white'></i></a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <form action="https://api.web3forms.com/submit" method="POST">
                  <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                  <input
                    type='text'
                    placeholder='Name'
                    name="name"
                    className='form-control my-2'
                    required

                  />
                  <input
                    type='text'
                    placeholder='Mobile'
                    name="mobile"
                    className='form-control my-2'
                    required
                  />
                  <input
                    type='email'
                    placeholder='Email'
                    name="email"
                    className='form-control my-2'
                    required
                  />
                  <textarea
                    placeholder='Message'
                    name="message"
                    rows='4'
                    className='form-control my-2'
                    required
                  />
                  <div className='text-start mt-3 d-flex align-items-center'>
                    <span className='fs-2 text-rose'>→</span>
                    <input type='submit' value='Send' className='border-0 text-rose discuss-btn' />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Home Discuss*/}
    </div>
  )
}

export default LandownerShare