import React from 'react'
import './Location.css'
import './Home.css'
import { useEffect } from 'react'


function TermsAndConditions() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on page load
    }, []);
    return (
        <div className='location-main'>
            <div className='container-fluid'>
                <div className="container py-5">
                    <h1 className="text-center syne-title mb-4">Terms & Conditions<span className='text-rose'>.</span></h1>

                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <p className='text-secondary'>
                                Welcome to Tattva Hi Life. These Terms & Conditions govern your access to and use of our website and services. By accessing or using our services, you agree to abide by these terms.
                            </p>

                            <h4 className='syne-title py-3'>Scope of Services</h4>
                            <p className='text-secondary'>
                                Tattva Hi Life provides a range of real estate services, including but not limited to, property advisory, market research, analytics, and construction management. Our services are designed to support your real estate goals in a professional and effective manner.

                            </p>

                            <h4 className='syne-title py-3'>User Obligations</h4>
                            <p className='text-secondary'>
                                As a user of Tattva Hi Life's services, you agree to:
                                <ul className='list-unstyled'>
                                    <li className='my-2'><i class="fas fa-check-double text-rose me-2"></i>Use our services solely for lawful purposes.</li>
                                    <li className='my-2'><i class="fas fa-check-double text-rose me-2"></i>Provide accurate and up-to-date information as required.</li>
                                    <li className='my-2'><i class="fas fa-check-double text-rose me-2"></i>Refrain from infringing on the rights of others or engaging in unauthorized activity on our platform.</li>
                                </ul>
                            </p>

                            <h4 className='syne-title py-3'>Limitation of Liability</h4>
                            <p className='text-secondary'>
                                While we strive to ensure the accuracy of our services, Tattva Hi Life makes no warranties or representations about the completeness or reliability of our content. We are not liable for any loss or damage incurred in connection with the use of our services.
                            </p>

                            <h4 className='syne-title py-3'>Intellectual Property Rights</h4>
                            <p className='text-secondary'>
                                All content provided on our website, including text, graphics, logos, and images, is the intellectual property of Tattva Hi Life. Unauthorized use or reproduction of this content is strictly prohibited.
                            </p>

                            <h4 className='syne-title py-3'>Termination of Services</h4>
                            <p className='text-secondary'>
                                Tattva Hi Life reserves the right to suspend or terminate access to our services if we determine that there has been a violation of our terms.
                            </p>
                            <h4 className='syne-title py-3'>Modifications to Terms</h4>
                            <p className='text-secondary'>
                                We may update these Terms & Conditions periodically to reflect changes in our services or legal requirements. Continued use of our services after such updates indicates acceptance of the revised terms.

                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Home Discuss*/}
            <div className='container-fluid py-5 home-discuss'>
                <div className='container py-5 home-discuss-inner bg-light p-4 rounded-15 shadow-sm'>
                    <h1 className='syne-title mb-4 text-start'>
                        LET'S DISCUSS<br />YOUR IDEA<span className='text-rose'>.</span>
                    </h1>
                    <div className='discuss-inner'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6'>
                                <div className='discuss-left text-start'>
                                    <p className='text-secondary'>
                                        We’re excited to hear your vision! Whether you have a concept or need help brainstorming, our team is ready to collaborate. Let’s connect and turn your idea into reality—schedule a meeting with us today!
                                    </p>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-envelope me-2'></i>
                                        <p className='mb-0 text-secondary'>rakesh.tattvahilife@gmail.com</p>
                                    </div>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-mobile-alt me-2'></i>
                                        <p className='mb-0 text-secondary'>+91 88970 35800</p>
                                    </div>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-map-marker-alt me-2'></i>
                                        <p className='mb-0 text-secondary'>10, Prashanthi Hills, Khajaguda, Gachibowli, Hyderabad</p>
                                    </div>
                                    <div className='discuss-icons d-flex'>
                                        <a href='https://www.linkedin.com/in/tattva-hi-life-735094319/' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-white'></i></a>
                                        <a href='https://x.com/TattvaHiLife' className='text-decoration-none'><i className='fab fa-twitter text-white'></i></a>
                                        <a href='https://www.facebook.com/profile.php?id=100086552704523' className='text-decoration-none'><i className='fab fa-facebook-f text-white'></i></a>
                                        <a href='https://www.instagram.com/tattvahilife_international/' className='text-decoration-none'><i className='fab fa-instagram text-white'></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <form action="https://api.web3forms.com/submit" method="POST">
                                    <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                                    <input
                                        type='text'
                                        placeholder='Name'
                                        name="name"
                                        className='form-control my-2'
                                        required

                                    />
                                    <input
                                        type='text'
                                        placeholder='Mobile'
                                        name="mobile"
                                        className='form-control my-2'
                                        required
                                    />
                                    <input
                                        type='email'
                                        placeholder='Email'
                                        name="email"
                                        className='form-control my-2'
                                        required
                                    />
                                    <textarea
                                        placeholder='Message'
                                        name="message"
                                        rows='4'
                                        className='form-control my-2'
                                        required
                                    />
                                    <div className='text-start mt-3 d-flex align-items-center'>
                                        <span className='fs-2 text-rose'>→</span>
                                        <input type='submit' value='Send' className='border-0 text-rose discuss-btn' />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Home Discuss*/}
        </div>
    )
}

export default TermsAndConditions