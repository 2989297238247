// src/pages/About.js
import React from 'react';
import { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import OwlCarousel from 'react-owl-carousel'; // Import OwlCarousel
// import 'owl.carousel/dist/assets/owl.carousel.css'; // OwlCarousel styles
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import './About.css';
import About_Story from '../images/about-story.png'
import About_Mission from '../images/about-mission.png'
// import About_Team_One from '../images/team-one.png'
// import About_Team_Two from '../images/team-two.png'
// import About_Team_Three from '../images/team-three.png'
import About_Founder_One from '../images/founder-one.png'
import About_Founder_Two from '../images/founder-two.png'
// import Discuss_Img from '../assets/images/discuss-img.png'


const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on page load
  }, []);
  // State to manage the theme mode (light/dark)
  const [theme, setTheme] = useState('light'); // Default theme

  useEffect(() => {
    // Check for stored theme in localStorage, and apply it.
    const currentTheme = localStorage.getItem('theme') || 'light';
    setTheme(currentTheme);
  }, []);


  return (
    <div className="about-main">
      {/* About Hero */}
      <div className='about-hero'>
        <div className='about-hero-inner d-flex justify-content-center align-items-center'>
          <div>
            <h1 className='syne-title'>ABOUT US<span className='text-rose'>.</span></h1>
            <p className='text-center'>Home / <span className='text-rose'>About Us</span></p>
          </div>
        </div>
      </div>
      {/* About Hero */}
      {/* Home About */}
      <div className='home-about container-fluid py-4'>
        <div className='home-about container py-4'>
          <div className='row'>
            <div className='col-lg-6 my-3 p-2'>
              <div className='home-about-left text-start'>
                <h3 className='text-start my-3 syne-title'><span className='text-rose'>EXPERT</span> IN</h3>
                <div className='row my-3'>
                  <div className='col-2'></div>
                  <div className='col-10'>
                    <h3 className=' syne-title'> HOUSE <span className='text-rose'>DESIGN</span></h3>
                  </div>
                </div>
                <p className='mt-3 text-secondary'>Over the past 6+ years, the founders of Tattva Hi Life have successfully
                  undertaken projects ranging from 40 lakh to 200 crore in value, establishing a
                  robust reputation in Hyderabad's competitive real estate sector. Their
                  commitment to values such as trust, integrity, reliability, and uncompromising
                  quality has been integral to every joint venture they undertake.</p>
                <p className='mt-3 text-secondary'>Specializing in creating aesthetically appealing spaces that prioritize comfort
                  and luxury, each project by Tattva Hi Life has become a highly sought-after
                  address in the city. Their portfolio includes a diverse range of residential and
                  commercial developments, each designed with meticulous attention to detail and
                  a focus on delivering exceptional value and solid returns.
                </p>
              </div>
            </div>
            <div className="col-lg-6 my-3 p-2 d-flex align-items-center jusfify-content-center">
              <div className='home-about-right flex-wrap-reverse w-100'>
                <div className="row justify-content-between py-4">
                  <div className="col-6 home-about-num text-center">
                    <h3 className='text-rose fs-2 fw-bold'>100+</h3>
                    <p className='text-secondary'>Completed Projects</p>
                  </div>
                  <div className="col-6 text-center">
                    <h3 className='text-rose fs-2 fw-bold'>30+</h3>
                    <p className='text-secondary'>Team Members</p>
                  </div>
                </div>
                <div className='row justify-content-between '>
                  <div className="col-6 home-about-num text-center">
                    <h3 className='text-rose fs-2 fw-bold'>4.2M Sq. Ft.</h3>
                    <p className='text-secondary'>Sold Out</p>
                  </div>
                  <div className="col-6 text-center">
                    <h3 className='text-rose fs-2 fw-bold'>250+</h3>
                    <p className='text-secondary'> Direct Channel Partners</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* Home About */}
      {/* About Story */}
      <div className='about-story container-fluid py-4'>
        <div className='about-story-inner container py-4'>
          <div className='row'>
            <div className='col-lg-6 my-3 d-flex justify-content-center align-items-center'>
              <img src={About_Story} className='w-75' />
            </div>
            <div className='col-lg-6 my-3'>
              <h1 className='syne-title'>OUR STORY<span className='text-rose'>.</span></h1>
              <p className='text-secondary'>The company’s expertise extends beyond development to encompass pre-sales and investment fund-raising strategies. Through tailored approaches, they attract prospective buyers and secure crucial funding, facilitating project progression. Navigating the regulatory landscape post-RERA (Real Estate Regulatory Authority) is paramount in today’s real estate environment. Tattva Hi Life adeptly formulates sales strategies that comply with regulatory requirements, ensuring transparency and consumer protection.</p>
              <p className='text-secondary'>Sales, marketing, and advertisement efforts are meticulously orchestrated to maximize reach and engagement. Leveraging a multi-channel approach, Tattva Hi Life effectively showcases its projects to target audiences, driving demand and conversion.</p>
            </div>
          </div>
        </div>
      </div>
      {/* About Story */}
      {/* About mission */}
      <div className='about-mission container-fluid py-4'>
        <div className='about-mission-inner container py-4'>
          <div className='row align-items-center flex-lg-row flex-column-reverse'>
            <div className='col-lg-6 my-3'>
              <h1 className='syne-title'>VISION<span className='text-rose'> & </span>MISSION</h1>
              <p className='text-secondary'>At Tattva Hi Life, our vision is to lead the future of urban living through innovative, sustainable real estate development. We are committed to creating modern, high-quality spaces that cater to evolving consumer needs while delivering strong returns for investors. By combining strategic land acquisition, cutting-edge design, and a seamless customer experience, we ensure regulatory compliance and transparency at every stage. Our mission is to build thriving communities that set new standards in the real estate industry, fostering lasting relationships and contributing to the growth of the urban landscape.</p>

            </div>
            <div className='col-lg-6 my-3 d-flex justify-content-center align-items-center'>
              <img src={About_Mission} className='w-75' />
            </div>
          </div>
        </div>
      </div>
      {/* About mission */}
      {/* About Why Us */}
      <div className='home-about about-why container-fluid py-4'>
        <div className='home-about container py-4'>
          <div className='row flex-wrap-reverse'>
            <div className="col-lg-6 d-flex justify-content-center align-items-end my-3 p-2">
              <div className='row home-why-right'>
                <div className="col-md-6 mb-4">
                  <div className="why-card text-start">
                    <div className="icon-circle">
                      <i className="fas fa-certificate text-rose"></i>
                    </div>
                    <h5>Fully Licensed</h5>
                    <p className='text-secondary'> We are fully licensed, ensuring all projects meet regulatory standards.

                    </p>
                  </div>
                  <div className="why-card text-start mt-4">
                    <div className="icon-circle">
                      <i className="fas fa-lightbulb text-rose"></i>
                    </div>
                    <h5>Innovation</h5>
                    <p className='text-secondary'>We bring innovation and modern design to every project.

                    </p>
                  </div>
                </div>

                <div className="col-md-6 mb-4">
                  <div className="why-card text-start">
                    <div className="icon-circle">
                      <i className="fas fa-shield-alt text-rose"></i>
                    </div>
                    <h5>Insured</h5>
                    <p className='text-secondary'>Our projects are fully insured for added security.
                    </p>
                  </div>
                  <div className="why-card text-start mt-4">
                    <div className="icon-circle">
                      <i className="fas fa-headset text-rose"></i>
                    </div>
                    <h5>24/7 Support</h5>
                    <p className='text-secondary'>We offer 24/7 client support for any needs.
                    </p>
                  </div>
                </div>

              </div>
            </div>
            <div className='col-lg-6 my-3 p-2 d-flex align-items-center'>
              <div className='home-about-left text-lg-end p-2'>
                <h1 className='syne-title'>WHY US<span className='text-rose'>.</span></h1>
                <h5>YOUR TRUSTED PARTNER FOR REAL ESTATE EXCELLENCE</h5>
                <p className='mt-3 text-secondary'> <span className='text-rose'>Tattva Hi Life</span> stands out for its strategic approach to real estate development. We ensure high-value investments through expert land acquisition, supported by data-driven insights. Our projects are founded on meticulous feasibility studies, which guarantee market relevance and financial viability, setting the stage for successful outcomes.</p>
                <p className='mt-3 text-secondary'>In addition to our strategic planning, we prioritize innovative design, crafting modern living spaces that cater to today’s lifestyle preferences. Our commitment to blending expertise, precision, and creativity in every development allows us to deliver exceptional results that resonate with our clients and stakeholders.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* About Why Us */}
      {/* About Team Slider */}
      {/* <div className="about-team-main project-slider-container py-4">
        <div className="container py-4">
          <div className="row mb-4">
            <div className="col-lg-6">
              <h1 className='syne-title'>TEAM<span className='text-rose'>.</span></h1>
              <p className='text-secondary'>
                Our team brings together expertise, innovation, and dedication to deliver outstanding results. We prioritize transparency and collaboration, ensuring every project exceeds expectations. Committed to excellence, we build lasting partnerships that drive success and growth.</p>
            </div>
            <div className="col-lg-2"></div>
            <div className="col-lg-4">
           
            </div>
          </div>
          <div className="slider-wrapper">
            <OwlCarousel
            
              className="owl-theme"
              loop
              margin={10}
              items={1}
              autoplay={true}
              autoplayTimeout={4000}
              smartSpeed={800}
              nav={true}
        
              dots={false}
              responsive={{
                0: { items: 1 },
                768: { items: 2 },
                1000: { items: 3 },
              }}
            >
              <div className="item position-relative p-5 m-2 team-item rounded">
                <img src={About_Team_One} className='mb-4 rounded' alt="Project 1" />
                <div className='team-details text-center'>
                  <h6>Team Memeber Name</h6>
                  <p>Team Member Position</p>
                  <div className='discuss-icons d-flex justify-content-center'>
                    <a href='/about' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-rose'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-twitter text-rose'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-facebook-f text-rose'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-instagram text-rose'></i></a>
                  </div>
                </div>

              </div>
              <div className="item position-relative p-5 m-2 team-item rounded">
                <img src={About_Team_Two} className='mb-4 rounded' alt="Project 1" />
                <div className='team-details text-center'>
                  <h6>Team Memeber Name</h6>
                  <p>Team Member Position</p>
                  <div className='discuss-icons d-flex justify-content-center'>
                    <a href='/about' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-rose'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-twitter text-rose'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-facebook-f text-rose'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-instagram text-rose'></i></a>
                  </div>
                </div>
              </div>
              <div className="item position-relative p-5 m-2 team-item rounded">
                <img src={About_Team_Three} className='mb-4 rounded' alt="Project 1" />
                <div className='team-details text-center'>
                  <h6>Team Memeber Name</h6>
                  <p>Team Member Position</p>
                  <div className='discuss-icons d-flex justify-content-center'>
                    <a href='/about' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-rose'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-twitter text-rose'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-facebook-f text-rose'></i></a>
                    <a href='#' className='text-decoration-none'><i className='fab fa-instagram text-rose'></i></a>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div> */}
      {/* About Team Slider */}
      {/* About Teams */}
      <div className='about-team container-fluid py-4'>
        <div className='about-team-inner container py-4'>
          <h3 className='syne-title mb-4 text-center text-uppercase'><span className='text-rose'>Founders & Directors  </span> of the Company</h3>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6 d-flex justify-content-center align-items-center my-2'>
              <div className='founder-detail-one'>
                <img src={About_Founder_Two} className='img-fluid rounded-circle my-3 shadow-sm' />
                <h4 className='text-rose text-center'>G Satyanarayana</h4>
              </div>
            </div>
            <div className='col-lg-4 col-md-6 d-flex justify-content-center align-items-center my-2'>
              <div className='founder-detail-one'>
                <img src={About_Founder_One} className='img-fluid rounded-circle my-3 shadow-sm' />
                <h4 className='text-rose text-center'>Rakesh Ranjan</h4>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-8'>
              <p className='text-secondary'>
                Tattva Hi Life was founded by G Satyanarayana and Rakesh Ranjan, who both come
                from robust backgrounds in Information Technology, having gained valuable
                experience at renowned companies such as IBM, L&T, SAP, Infosys, and Cognizant.
                Their journey into the real estate sector stems from a shared passion for the industry,
                where they have made significant contributions over the span of 6+ years.
              </p>
              <p className='text-secondary'>
                During this time, Satyanarayana and Rakesh have been actively involved in a wide
                array of real estate projects, encompassing residential developments, commercial
                properties, and expensive township initiatives. Their expertise and dedication have
                enabled them to collaborate successfully on various ventures, including high-rise
                apartment complexes, large-scale villa developments, and plotting projects.
              </p>
              <p className='text-secondary'>
                Their combined experience and strategic partnership underscore their commitment
                to delivering quality and innovative solutions in the real estate market. Through
                Tattva Hi Life, they continue to make substantial contributions, leveraging their IT
                acumen to drive forward-thinking approaches within the industry.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* About Teams */}
      {/* Home Discuss*/}
      <div className='container-fluid py-5 home-discuss'>
        <div className='container py-5 home-discuss-inner bg-light p-4 rounded-15 shadow-sm'>
          <h1 className='syne-title mb-4 text-start'>
            LET'S DISCUSS<br />YOUR IDEA<span className='text-rose'>.</span>
          </h1>
          <div className='discuss-inner'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='discuss-left text-start'>
                  <p className='text-secondary'>
                    We’re excited to hear your vision! Whether you have a concept or need help brainstorming, our team is ready to collaborate. Let’s connect and turn your idea into reality—schedule a meeting with us today!
                  </p>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-envelope me-2'></i>
                    <p className='mb-0 text-secondary'>rakesh.tattvahilife@gmail.com</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-mobile-alt me-2'></i>
                    <p className='mb-0 text-secondary'>+91 88970 35800</p>
                  </div>
                  <div className='discuss-mail d-flex align-items-center my-3'>
                    <i className='fas fa-map-marker-alt me-2'></i>
                    <p className='mb-0 text-secondary'>10, Prashanthi Hills, Khajaguda, Gachibowli, Hyderabad</p>
                  </div>
                  <div className='discuss-icons d-flex'>
                    <a href='https://www.linkedin.com/in/tattva-hi-life-735094319/' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-white'></i></a>
                    <a href='https://x.com/TattvaHiLife' className='text-decoration-none'><i className='fab fa-twitter text-white'></i></a>
                    <a href='https://www.facebook.com/profile.php?id=100086552704523' className='text-decoration-none'><i className='fab fa-facebook-f text-white'></i></a>
                    <a href='https://www.instagram.com/tattvahilife_international/' className='text-decoration-none'><i className='fab fa-instagram text-white'></i></a>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <form action="https://api.web3forms.com/submit" method="POST">
                  <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                  <input
                    type='text'
                    placeholder='Name'
                    name="name"
                    className='form-control my-2'
                    required

                  />
                  <input
                    type='text'
                    placeholder='Mobile'
                    name="mobile"
                    className='form-control my-2'
                    required
                  />
                  <input
                    type='email'
                    placeholder='Email'
                    name="email"
                    className='form-control my-2'
                    required
                  />
                  <textarea
                    placeholder='Message'
                    name="message"
                    rows='4'
                    className='form-control my-2'
                    required
                  />
                  <div className='text-start mt-3 d-flex align-items-center'>
                    <span className='fs-2 text-rose'>→</span>
                    <input type='submit' value='Send' className='border-0 text-rose discuss-btn' />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Home Discuss*/}
    </div>
  );
};

export default About;
