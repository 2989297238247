import React from 'react'
import './LandownerShare.css'
import './Project-Details.css'
import Trilight_Img from '../images/hyd-projects/Trilight.jpg'
import { useEffect } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { FaBuilding, FaCouch, FaArrowCircleUp, FaHighlighter, FaGolfBall } from 'react-icons/fa';
import Trilight_One from '../images/Trilight-gal-one.jpg'
import Trilight_Two from '../images/Trilight-gal-two.jpg'
import Trilight_Three from '../images/Trilight-gal-three.jpg'
import Trilight_Four from '../images/Trilight-gal-four.jpg'



function Trilight() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on page load
    }, []);

    return (
        <div className='landowner-main'>
            {/* Landowner Hero */}
            <div className='trilight-hero landowner-hero d-flex justify-content-center align-items-center'>
                <h1 className='syne-title text-white text-uppercase'> <span className='text-rose'>T</span>he <span className='text-rose'>T</span>rilight</h1>
            </div>
            {/* Landowner Hero */}
            {/* Landowner About */}
            <div className='landowner-about container-fluid py-4'>
                <div className='landowner-about-inner container py-4'>
                    <h4 className='syne-title text-center mb-4 fs-2'></h4>
                    <div className='row'>
                        <div className='col-lg-6 my-2'>
                            <img src={Trilight_Img} className='img-fluid shadow-sm rounded' />
                        </div>
                        <div className='col-lg-6 my-2 px-2 py-1 d-flex justify-content-center align-items-center'>

                            <div className='landowner-about-one-right text-secondary'>

                                <h4 className='syne-title mb-2 fs-3 text-dark'>Luxury High Rise
                                    3 | 4 | 5 BHK Apartments In Kokapet</h4>
                                <p>
                                    Welcome to a luxurious lifestyle redefined, where modern architecture meets timeless elegance. Immerse yourself in world-class amenities, thoughtfully crafted spaces, and unparalleled comfort that cater to your every need. Discover a living experience that blends sophistication, convenience, and tranquility, elevating your lifestyle to extraordinary heights.
                                </p>
                                <div className="highlights-container">
                                    <ul className="highlight-list">
                                        <li className="highlight-item">
                                            <FaCheckCircle className="icon text-rose" />
                                            <span>
                                                3 highrise towers namely Canopus 56 floors, Vega 46 floors and Rigel 49 floors
                                            </span>
                                        </li>
                                        <li className="highlight-item">
                                            <FaCheckCircle className="icon text-rose" />
                                            <span>
                                                462 high-quality built luxury residences spread across 4.23 acres
                                            </span>
                                        </li>
                                        <li className="highlight-item">
                                            <FaCheckCircle className="icon text-rose" />
                                            <span>
                                                Grand outdoor living areas that create a lavish yet relaxed lifestyle for the owners
                                            </span>
                                        </li>
                                        <li className="highlight-item">
                                            <FaCheckCircle className="icon text-rose" />
                                            <span>
                                                The construction of this project is being managed by the ever trusted TATA projects
                                            </span>
                                        </li>
                                        <li className="highlight-item">
                                            <FaCheckCircle className="icon text-rose" />
                                            <span>100% Vaasthu Compliant</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-lg-6 my-2 p-3 d-flex justify-content-center align-items-center'>
              <div className='landowner-about-one-right'>
                <p>
                In the dynamic world of real estate investment, innovative opportunities are constantly emerging to cater to the diverse needs of investors. One such approach gaining traction is the concept of Land Owner Shares (LOS) and the associated benefits of investing in apartments that offer these shares.</p>
                <p>Land Owner Shares provide an exciting and accessible avenue for real estate investment, making it easier for investors to participate in property ownership through this modern strategy.</p>
              </div>
            </div>
            <div className='col-lg-6 my-2'>
              <img src={Landowner_About_Two} className='img-fluid shadow-sm rounded' />
            </div> */}
                    </div>
                </div>
            </div>
            {/* Landowner About */}
            {/* Landowner Benefits */}
            <div className='landowner-benefit container-fluid py-4 bg-light'>
                <div className='landowner-benefit-inner container py-4'>
                    <h1 className='syne-title text-center'>Key Highlights<span className='text-rose'>.</span></h1>
                    <p className='text-center text-secondary'>Discover the unmatched luxury and exclusivity with three stunning towers, surrounded by 78% open area that offers abundant green spaces. Our apartments come in various sizes ranging from 2,888 Sq. ft. to 5,777 Sq. ft., allowing you to choose the perfect home for your needs.</p>
                    <div className="container my-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                        <div className="card-icon mb-3">
                                            <i className="fas fa-building fa-3x text-rose"></i>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">3 Towers</h5>
                                            <p className="text-secondary">The project features three high-rise towers, providing ample space and panoramic views for every resident.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                        <div className="card-icon mb-3">
                                            <i className="fas fa-tree fa-3x text-rose"></i>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">78% Open Area</h5>
                                            <p className="text-secondary">With 78% open area, the project offers abundant greenery and open spaces for a healthier and more relaxing environment.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                        <div className="card-icon mb-3">
                                            <i className="fas fa-ruler-combined fa-3x text-rose"></i>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">Apartment Sizes</h5>
                                            <p className="text-secondary">Apartments range from 2,888 Sq. ft. to 5,777 Sq. ft., providing a variety of spacious options to suit your needs.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                        <div className="card-icon mb-3">
                                            <i className="fas fa-compass fa-3x text-rose"></i>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">360° View from Central Courtyard</h5>
                                            <p className="text-secondary">Enjoy stunning 360° views from the central courtyard, offering an unparalleled visual experience from every angle.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                        <div className="card-icon mb-3">
                                            <i className="fas fa-archive fa-3x text-rose"></i>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">Limited Edition Homes</h5>
                                            <p className="text-secondary">A limited number of homes available from the 38th to the 56th floor, offering exclusive living experiences with breathtaking views.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 my-3">
                                    <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                        <div className="card-icon mb-3">
                                            <i className="fas fa-building fa-3x text-rose"></i>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">TATA Projects</h5>
                                            <p className="text-secondary">Built by Tata Projects, known for delivering exceptional quality and excellence in construction.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* Landowner Benefits */}
            {/* Project Gallery */}
            <div className='gallery container-fluid py-4'>
                <div className='gallery-inner container py-4'>
                    <h1 className='syne-title'>GALLERY<span className='text-rose'>.</span></h1>
                    <div className='row'>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Trilight_One} className='img-fluid rounded-10' />
                        </div>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Trilight_Two} className='img-fluid rounded-10' />
                        </div>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Trilight_Three} className='img-fluid rounded-10' />
                        </div>
                        <div className='col-lg-3 my-3 h-100'>
                            <img src={Trilight_Four} className='img-fluid rounded-10' />
                        </div>
                    </div>
                </div>
            </div>
            {/* Project Gallery */}
            {/* Landowner Benefits */}
            <div className='landowner-benefit container-fluid py-4 bg-light'>
                <div className='landowner-benefit-inner container py-4'>
                    <h1 className='syne-title text-center'>Amenities<span className='text-rose'>.</span></h1>
                    <p className='text-center text-secondary'>Enjoy a wide range of facilities, including multiple lounges for relaxation, single-loaded corridors for privacy, and a golf simulator to practice your skills. These offerings ensure you have everything you need to live in style and sophistication.</p>
                    <div className="container my-2">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 my-3">
                                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                    <div className="card-icon mb-3">
                                        <i className="fas fa-building fa-3x text-rose"></i>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title fw-bold syne-title">Three Club Houses</h5>
                                        <p className="text-secondary">
                                            At different levels that are one of a kind, offering exclusive and luxurious experiences.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-3">
                                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                    <div className="card-icon mb-3">
                                        <i className="fas fa-highlighter fa-3x text-rose"></i>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title fw-bold syne-title">High Ceilings</h5>
                                        <p className="text-secondary">
                                            Lobbies designed with sheer opulence to create a grand entrance for residents.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-3">
                                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                    <div className="card-icon mb-3">
                                        <i className="fas fa-couch fa-3x text-rose"></i>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title fw-bold syne-title">Multiple Lounges</h5>
                                        <p className="text-secondary">
                                            For leisure, entertainment, and private conversations in comfort and style.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-3">
                                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                    <div className="card-icon mb-3">
                                        <i className="fas fa-arrow-circle-up fa-3x text-rose"></i>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title fw-bold syne-title">Private Lifts</h5>
                                        <p className="text-secondary">
                                            That open up directly to your house, ensuring convenience and privacy.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-3">
                                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                    <div className="card-icon mb-3">
                                        <i className="fas fa-building fa-3x text-rose"></i>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title fw-bold syne-title">Single Loaded Corridors</h5>
                                        <p className="text-secondary">
                                            Offering complete privacy and a first-of-its-kind design for residents.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 my-3">
                                <div className="card h-100 text-center p-4 border-0 shadow-sm">
                                    <div className="card-icon mb-3">
                                        <i className="fas fa-golf-ball fa-3x text-rose"></i>
                                    </div>
                                    <div className="card-body">
                                        <h5 className="card-title fw-bold syne-title">Golf Simulator</h5>
                                        <p className="text-secondary">
                                            Perfect for practicing your tee-off and enjoying a sophisticated sport.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* Landowner Benefits */}
            {/* Contact Map */}
            <div className='contact-map container'>
                <div className='container row py-4 justify-content-center w-100'>
                    <div className='col-lg-6'>
                        <h1 className='syne-title text-start'>LOCA<span className='text-rose'>T</span>ION<span className='text-rose'>.</span></h1>
                        <p className='text-secondary text-start'>Explore our diverse range of locations, each offering unique experiences and exceptional services tailored to meet your needs.</p>
                    </div>
                    <div className='col-lg-6'></div>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15228.873157931499!2d78.3330205!3d17.4013088!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb951cc98404bb%3A0x17bd70f88466519d!2sThe%20Trilight!5e0!3m2!1sen!2sin!4v1732614598488!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            {/* Contact Map */}
            {/* Home Projects */}
            {/* <div className='home-landowner container-fluid py-4'>
                <div className='home-landowner-inner container py-4'>
                    <h1 className='syne-title text-center mb-3'>Our Projects<span className='text-rose'>.</span></h1>
                    <p className='mb-4 text-secondary'>Our Project Partnership offers property owners a unique chance to collaborate with Tattva Hi Life in lucrative real estate developments. By partnering with us, owners can leverage their land to secure a substantial share in project profits without the complexities of construction management. With our expert guidance and commitment to transparency, we help property owners maximize their returns while they retain control over their valuable assets.</p>
                    <div className='home-land-cards'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Ten} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>ASBL Spire</h4>
                                        <p className='mb-1'>Kokapet, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-asbl-spire" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Eleven} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>ASBL Spectra</h4>
                                        <p className='mb-1'>Financial District</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-asbl-spectra" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_One} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>IRIS By Raghava</h4>
                                        <p className='mb-1'>Raidugam, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-iris" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Two} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Wave By Raghava</h4>
                                        <p className='mb-1'>Tellapur, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-wave" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Three} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Pearl By Auro Reality</h4>
                                        <p className='mb-1'>Hi-Tech City, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-pearl" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Four} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Rajpushpa Pristinia</h4>
                                        <p className='mb-1'>Kokapet, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-rajpushpa" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Five} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>My Home Apas</h4>
                                        <p className='mb-1'>Kokapet, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-my-home" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Six} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Upcoming Luxury Apartments</h4>
                                        <p className='mb-1'>Neopolis, Kokapet</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-upcoming" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Seven} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Upcoming Luxury Apartments</h4>
                                        <p className='mb-1'>Narsingi, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-upcoming" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Eight} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Candeur Lakescape</h4>
                                        <p className='mb-1'>Kondapur, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-candeur" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 my-3'>
                                <div className='home-land-card h-100 shadow-sm'>
                                    <img src={Home_Project_Nine} className='img-fluid home-land-card-img' />
                                    <div className='home-land-card-content p-3 text-center bg-white'>
                                        <h4 className='fw-bold'>Luxury Apartments</h4>
                                        <p className='mb-1'>Kondapur, Hyderabad</p>
                                        <div className='home-about-btn text-center'>
                                            <Link to="/project-luxury" className="text-rose"><FaLongArrowAltRight /> Know More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* Home Projects */}
            {/* Home Discuss*/}
            <div className='container-fluid py-5 home-discuss'>
                <div className='container py-5 home-discuss-inner bg-light p-4 rounded-15 shadow-sm'>
                    <h1 className='syne-title mb-4 text-start'>
                        LET'S DISCUSS<br />YOUR IDEA<span className='text-rose'>.</span>
                    </h1>

                    <div className='discuss-inner'>
                        <div className='row align-items-center'>
                            <div className='col-lg-6'>
                                <div className='discuss-left text-start'>
                                    <p className='text-secondary'>
                                        We’re excited to hear your vision! Whether you have a concept or need help brainstorming, our team is ready to collaborate. Let’s connect and turn your idea into reality—schedule a meeting with us today!
                                    </p>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-envelope me-2'></i>
                                        <p className='mb-0 text-secondary'>rakesh.tattvahilife@gmail.com</p>
                                    </div>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-mobile-alt me-2'></i>
                                        <p className='mb-0 text-secondary'>+91 88970 35800</p>
                                    </div>
                                    <div className='discuss-mail d-flex align-items-center my-3'>
                                        <i className='fas fa-map-marker-alt me-2'></i>
                                        <p className='mb-0 text-secondary'>10, Prashanthi Hills, Khajaguda, Gachibowli, Hyderabad</p>
                                    </div>
                                    <div className='discuss-icons d-flex'>
                                        <a href='https://www.linkedin.com/in/tattva-hi-life-735094319/' target='blank' className='text-decoration-none'><i className='fab fa-linkedin-in text-white'></i></a>
                                        <a href='https://x.com/TattvaHiLife' className='text-decoration-none'><i className='fab fa-twitter text-white'></i></a>
                                        <a href='https://www.facebook.com/profile.php?id=100086552704523' className='text-decoration-none'><i className='fab fa-facebook-f text-white'></i></a>
                                        <a href='https://www.instagram.com/tattvahilife_international/' className='text-decoration-none'><i className='fab fa-instagram text-white'></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <form action="https://api.web3forms.com/submit" method="POST">
                                    <input type="hidden" name="access_key" value="d567d7ce-8cc7-4fdd-a9ae-66c9c3461e68" />
                                    <input
                                        type='text'
                                        placeholder='Name'
                                        name="name"
                                        className='form-control my-2'
                                        required

                                    />
                                    <input
                                        type='text'
                                        placeholder='Mobile'
                                        name="mobile"
                                        className='form-control my-2'
                                        required
                                    />
                                    <input
                                        type='email'
                                        placeholder='Email'
                                        name="email"
                                        className='form-control my-2'
                                        required
                                    />
                                    <textarea
                                        placeholder='Message'
                                        name="message"
                                        rows='4'
                                        className='form-control my-2'
                                        required
                                    />
                                    <div className='text-start mt-3 d-flex align-items-center'>
                                        <span className='fs-2 text-rose'>→</span>
                                        <input type='submit' value='Send' className='border-0 text-rose discuss-btn' />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Home Discuss*/}
        </div>
    )
}

export default Trilight