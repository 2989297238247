import React, { useState, useEffect } from 'react';
import './Footer.css';
import Footer_Tattva from '../images/footer-tattva.png';


const Footer = () => {
    const [theme, setTheme] = useState('light');

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'light';
        setTheme(savedTheme);
        document.documentElement.setAttribute('data-theme', savedTheme);
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        document.documentElement.setAttribute('data-theme', newTheme);
        localStorage.setItem('theme', newTheme);
    };

    return (
        <footer className={`footer ${theme === 'light' ? 'bg-light text-black' : 'bg-dark text-white'} py-4`}>
            {/* Owl Carousel */}
            {/* First Carousel - Default (Left to Right) */}
            {/* First Carousel - Default (Left to Right) */}
            {/* <OwlCarousel 
                className="owl-theme foot-carousel my-3"
                loop
                margin={10}
                autoplay
                autoplayTimeout={1000} // Set the time between slides
                autoplayHoverPause={false} // Prevents pause on hover
                dots={false} // Disable dots
                nav={false} // Disable navigation arrows
                autoplaySpeed={1000} // Speed of the sliding transition
                responsive={{
                    0: { items: 2 },
                    600: { items: 4 },
                    1000: { items: 6 }
                }}
            >
                <div className="item">DEVELOPMENT</div>
                <div className="item">APPARTMENTS</div>
                <div className="item">RESIDENTIALS</div>
                <div className="item">PUBLIC</div>
                <div className="item">WORKPLACE</div>
                <div className="item">COMMERCIAL</div>
            </OwlCarousel> */}

            {/* Second Carousel - Right to Left */}
            {/* <OwlCarousel
                className="owl-theme foot-carousel my-3"
                loop
                margin={10}
                autoplay
                autoplayTimeout={1000} // Set the time between slides
                autoplayHoverPause={false} // Prevents pause on hover
                dots={false} // Disable dots
                nav={false} // Disable navigation arrows
                autoplaySpeed={1000} // Speed of the sliding transition
                rtl={true} 
                responsive={{
                    0: { items: 2 },
                    600: { items: 4 },
                    1000: { items: 6 }
                }}
            >
                <div className="item">DEVELOPMENT</div>
                <div className="item">APPARTMENTS</div>
                <div className="item">RESIDENTIALS</div>
                <div className="item">PUBLIC</div>
                <div className="item">WORKPLACE</div>
                <div className="item">COMMERCIAL</div>
            </OwlCarousel> */}
            {/* Owl Carousel End */}
            <div className='container-fluid py-4 px-4'>
                <img src={Footer_Tattva} className='img-fluid' alt="Footer Tattva" />
            </div>
            <div className="container py-4">
                <div className="row">
                    {/* Navigation Links */}
                    <div className="col-lg-3 col-md-4 col-6 my-2">
                        <h5 className="text-uppercase fw-bold mb-3">Navigation</h5>
                        <ul className="list-unstyled">
                            <li className='mb-2'><a href="/" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Home</a></li>
                            <li className='mb-2'><a href="/landowner" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Landowner Share</a></li>
                            <li className='mb-2'><a href="/about" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>About</a></li>
                            <li className='mb-2'><a href="/projects" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Projects</a></li>
                            <li className='mb-2'><a href="/events" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Events</a></li>
                            <li className='mb-2'><a href="/contact" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Contact Us</a></li>
                        </ul>
                    </div>

                    {/* Resources Links */}
                    <div className="col-lg-2 col-md-4 col-6 my-2">
                        <h5 className="text-uppercase fw-bold mb-3">Resources</h5>
                        <ul className="list-unstyled">
                            <li className='mb-2'><a href="#" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Blog</a></li>
                            <li className='mb-2'><a href="#" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Media</a></li>
                            <li className='mb-2'><a href="terms-conditions" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Terms & Conditions</a></li>
                            <li className='mb-2'><a href="privacy-policy" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Privacy Policy</a></li>
                        </ul>
                    </div>

                    {/* Social Media Links */}
                    <div className="col-lg-2 col-md-4 my-2">
                        <h5 className="text-uppercase fw-bold mb-3">Social Media</h5>
                        <ul className="list-unstyled">
                            <li className='mb-2'><a href="https://www.linkedin.com/in/tattva-hi-life-735094319/" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Linkedin</a></li>
                            <li className='mb-2'><a href="https://www.instagram.com/tattvahilife_international/" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Instagram</a></li>
                            <li className='mb-2'><a href="https://x.com/TattvaHiLife" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Twitter</a></li>
                            <li className='mb-2'><a href="https://www.facebook.com/profile.php?id=100086552704523" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Facebook</a></li>
                            <li className='mb-2'><a href="https://www.youtube.com/@TattvaHiLifeInternational" className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>YouTube</a></li>
                        </ul>
                    </div>

                    {/* Newsletter Subscription */}
                    <div className="col-lg-5 col-md-6 my-2">
                        <h5 className="text-uppercase fw-bold">Subscribe to Your Newsletter</h5>
                        <p className={`${theme === 'light' ? 'text-black' : 'text-secondary'}`}>Latest news articles, sent to your inbox</p>
                        <div className="d-flex">
                            <input
                                type="email"
                                className={`form-control ${theme === 'light' ? 'text-black' : 'text-white'}`}
                                placeholder="Enter your mail here"
                                aria-label="Email"
                            />
                            <button className="btn bg-rose text-white ms-2">Subscribe</button>
                        </div>
                    </div>
                </div>

                {/* Footer Bottom */}
                <div className=" mt-4 row">
                    <div className=" col-md-6 my-2 text-md-start text-center">
                        <p className={`${theme === 'light' ? 'text-black' : 'text-white'} mb-0`}>©2024 <span className="text-rose">TATTVA HI LIFE</span>, All Rights Reserved</p>
                    </div>
                    <div className=' col-md-3 col-6 my-2'>
                        <a href="#top" className="text-rose text-decoration-none">Back to Top</a>
                    </div>
                    {/* Day/Night Mode Toggle */}
                    <div className="theme-toggle col-md-3 col-6 my-2">
                        <button
                            onClick={toggleTheme}
                            className={`btn ${theme === 'light' ? 'btn-light' : 'btn-dark'} daymode-btn`}
                        >
                            {theme === 'light' ? (
                                <>
                                    <span className="icon">
                                        <i className="fas fa-moon text-rose"></i>
                                    </span>
                                    <span> NIGHTMODE</span>
                                </>
                            ) : (
                                <>
                                    <span className="icon">
                                        <i className="fas fa-sun text-rose"></i>
                                    </span>
                                    <span> DAYMODE</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
