// src/data/ProjectsData.js
const projects = [
    {
        "id": "abhinandan",
        "title": "Abhinandana Infra - Emerald Commercial",
        "image": require("../images/projects/abhinandan.jpeg"),
        "description": "A premium commercial space located in the bustling area of Manikonda with easy access to urban amenities.",
        "mapLink": "https://maps.google.com/?q=Manikonda,Hyderabad",
        "features": [
            "Prime commercial location",
            "Modern infrastructure",
            "Proximity to business hubs"
        ],
        "location": "Manikonda, Hyderabad, Telangana"
    },
    {
        "id": "anvita-ivana",
        "title": "Anvita Ivana",
        "image": require("../images/projects/Anvita-Ivana.jpg"),
        "description": "Anvita Ivana offers contemporary living spaces in Kollur with essential amenities for modern living.",
        "mapLink": "https://maps.google.com/?q=Kollur,Hyderabad",
        "features": [
            "Affordable luxury apartments",
            "Lush green surroundings",
            "Close to tech hubs"
        ],
        "location": "Kollur, Hyderabad, Telangana"
    },
    {
        "id": "anvita-high-9",
        "title": "Anvita High 9",
        "image": require("../images/projects/Anvita-High-9.jpg"),
        "description": "Anvita High 9 provides modern residential spaces in Tellapur with a peaceful environment.",
        "mapLink": "https://maps.google.com/?q=Tellapur,Hyderabad",
        "features": [
            "Modern residential units",
            "Green landscapes",
            "Proximity to IT hubs"
        ],
        "location": "Tellapur, Hyderabad, Telangana"
    },
    {
        "id": "aparna-one",
        "title": "Aparna One",
        "image": require("../images/projects/Aparna-One.png"),
        "description": "Aparna One is a luxury high-rise residential project in the prime area of Shaikpet, offering scenic views and exclusive amenities.",
        "mapLink": "https://maps.google.com/?q=Shaikpet,Hyderabad",
        "features": [
            "High-rise luxury apartments",
            "Exclusive amenities",
            "Scenic city views"
        ],
        "location": "Shaikpet, Hyderabad, Telangana"
    },
    {
        "id": "aparna-cyber-heights",
        "title": "Aparna Cyber Heights",
        "image": require("../images/projects/Aparna-CyberHeights.png"),
        "description": "Luxury residential project near Osman Sagar with eco-friendly features and spacious apartments.",
        "mapLink": "https://maps.google.com/?q=Osman+Sagar,Hyderabad",
        "features": [
            "Sustainable design",
            "Spacious apartments",
            "Nature proximity"
        ],
        "location": "Osman Sagar, Hyderabad, Telangana"
    },
    {
        "id": "aparna-zenon",
        "title": "Aparna Zenon",
        "image": require("../images/projects/Aparna-Zenon.jpg"),
        "description": "High-end apartments in Nanakramguda providing luxurious and secure living with easy access to business hubs.",
        "mapLink": "https://maps.google.com/?q=Nanakramguda,Hyderabad",
        "features": [
            "Proximity to IT hubs",
            "High-end amenities",
            "Secure environment"
        ],
        "location": "Nanakramguda, Hyderabad, Telangana"
    },
    {
        "id": "aspire-realty-advaith-prem",
        "title": "Aspire Realty - Advaith Prem",
        "image": require("../images/projects/AspireRealityAdvaithPrem.jpg"),
        "description": "Spacious residential plots on Srisailam Highway, ideal for investment and future development.",
        "mapLink": "https://maps.google.com/?q=Srisailam+Highway,Hyderabad",
        "features": [
            "Spacious plots",
            "Good connectivity",
            "Ideal for investment"
        ],
        "location": "Srisailam Highway, Hyderabad, Telangana"
    },
    {
        "id": "aspire-realty-arogya",
        "title": "Aspire Realty - Arogya",
        "image": require("../images/projects/Aspire Realty - Arogya.jpg"),
        "description": "Residential plots in Karkalpahad, designed for a peaceful and healthy living environment.",
        "mapLink": "https://maps.google.com/?q=Karkalpahad,Hyderabad",
        "features": [
            "Health-oriented design",
            "Spacious plots",
            "Green surroundings"
        ],
        "location": "Karkalpahad, Hyderabad, Telangana"
    },
    {
        "id": "aspire-realty-avatar-3d",
        "title": "Aspire Realty - Avatar 3D",
        "image": require("../images/projects/Aspire Realty - Avatar 3D.jpg"),
        "description": "Modern 3D-designed residential plots along the Srisailam Highway in Kadthal.",
        "mapLink": "https://maps.google.com/?q=Kadthal,Srisailam+Highway,Hyderabad",
        "features": [
            "3D architecture",
            "Modern amenities",
            "Connectivity to Hyderabad"
        ],
        "location": "Kadthal, Srisailam Highway, Hyderabad, Telangana"
    },
    {
        "id": "auro-realty-the-pearl",
        "title": "Auro Realty - The Pearl",
        "image": require("../images/projects/Auro Realty - The Pear.jpg"),
        "description": "Luxury residences in Hi-Tech City by Auro Realty, situated near major technology firms.",
        "mapLink": "https://maps.google.com/?q=Hi-Tech+City,Hyderabad",
        "features": [
            "Luxury residences",
            "Located in Hi-Tech City",
            "Proximity to tech companies"
        ],
        "location": "Hi-Tech City, Hyderabad, Telangana"
    },
    {
        "id": "auro-realty-the-regent",
        "title": "Auro Realty - The Regent",
        "location": "Kondapur, Hyderabad",
        "location": "Kondapur, Hyderabad, India",
        "description": "Premium apartments in Kondapur with upscale living spaces and modern amenities.",
        "features": [
            "Upscale apartments",
            "Close to schools and shopping centers",
            "Modern design"
        ],
        "image": require("../images/projects/Auro Realty - The Regent.jpg"),
        "mapLink": "https://maps.google.com/?q=Kondapur,Hyderabad"
    },
    {
        "id": "brigade-hyderabad-world-trade-center",
        "title": "Brigade - Hyderabad World Trade Center",
        "location": "Neopolis, Kokapet, Hyderabad",
        "location": "Neopolis, Kokapet, Hyderabad, India",
        "description": "World-class commercial space by Brigade, located in Neopolis, Kokapet.",
        "features": [
            "World Trade Center amenities",
            "Prime business location",
            "High-tech infrastructure"
        ],
        "image": require("../images/projects/Brigade - Hyderabad World Trade Center.jpg"),
        "mapLink": "https://maps.google.com/?q=Neopolis,Kokapet,Hyderabad"
    },
    {
        "id": "candeur-crescent",
        "title": "Candeur Crescent",
        "location": "Lingampally, Hyderabad",
        "location": "Lingampally, Hyderabad, India",
        "description": "Residential project in Lingampally offering modern apartments and amenities.",
        "features": [
            "Affordable luxury",
            "Prime location",
            "Modern amenities"
        ],
        "image": require("../images/projects/Candeur Crescent.jpg"),
        "mapLink": "https://maps.google.com/?q=Lingampally,Hyderabad"
    },
    {
        "id": "candeur-lakescape",
        "title": "Candeur Lakescape",
        "location": "Kondapur, Hyderabad",
        "location": "Kondapur, Hyderabad, India",
        "description": "Modern apartments at Candeur Lakescape, offering tranquil lakeside views.",
        "features": [
            "Lakeside views",
            "Modern architecture",
            "Prime location"
        ],
        "image": require("../images/projects/Candeur Lakescape.jpg"),
        "mapLink": "https://maps.google.com/?q=Kondapur,Hyderabad"
    },
    {
        "id": "candeur-skyline",
        "title": "Candeur Skyline",
        "location": "Financial District, Hyderabad",
        "location": "Financial District, Hyderabad, India",
        "description": "High-rise apartments in the Financial District with scenic city views.",
        "features": [
            "City views",
            "Proximity to business centers",
            "High-rise living"
        ],
        "image": require("../images/projects/Candeur Skyline.jpg"),
        "mapLink": "https://maps.google.com/?q=Financial+District,Hyderabad"
    },
    {
        "id": "cybercity-westbrook",
        "title": "Cybercity Westbrook",
        "location": "Kokapet, Hyderabad",
        "location": "Kokapet, Hyderabad, India",
        "description": "Cybercity Westbrook offers luxury apartments with top-notch amenities.",
        "features": [
            "Luxury apartments",
            "Top-notch amenities",
            "Close to business hubs"
        ],
        "image": require("../images/projects/Cybercity Westbrook.jpg"),
        "mapLink": "https://maps.google.com/?q=Kokapet,Hyderabad"
    },
    {
        "id": "the-blue-oak-trilight",
        "title": "The Blue OAK - The Trilight",
        "location": "Kokapet, Hyderabad",
        "location": "Kokapet, Hyderabad, India",
        "description": "A luxury residential project offering a blend of style and comfort with scenic views.",
        "features": [
            "Luxury residences",
            "Modern architecture",
            "Scenic views"
        ],
        "image": require("../images/projects/The Blue OAK - The Trilight.jpg"),
        "mapLink": "https://maps.google.com/?q=Kokapet,Hyderabad"
    },
    {
        "id": "dsr-altitude",
        "title": "DSR Altitude",
        "location": "Osman Sagar, Hyderabad",
        "location": "Osman Sagar, Hyderabad, India",
        "description": "Luxurious high-rise apartments near Osman Sagar with scenic views.",
        "features": [
            "Scenic views",
            "High-rise buildings",
            "Luxurious amenities"
        ],
        "image": require("../images/projects/DSR Altitude.jpg"),
        "mapLink": "https://maps.google.com/?q=Osman+Sagar,Hyderabad"
    },
    {
        "id": "dsr-gopanpally",
        "title": "DSR Gopanpally",
        "location": "Gopanpally, Hyderabad",
        "location": "Gopanpally, Hyderabad, India",
        "description": "DSR Gopanpally offers luxurious living spaces in the serene locality of Gopanpally.",
        "features": [
            "Tranquil surroundings",
            "Spacious homes",
            "Modern amenities"
        ],
        "image": require("../images/projects/DSR Gopanpally.jpg"),
        "mapLink": "https://maps.google.com/?q=Gopanpally,Hyderabad"
    },
    {
        "id": "dsr-skymarq",
        "title": "DSR SkyMarq",
        "location": "Financial District, Hyderabad",
        "location": "Financial District, Hyderabad, India",
        "description": "A high-end residential project offering modern living in the heart of the Financial District.",
        "features": [
            "City views",
            "High-end living",
            "Close to major business centers"
        ],
        "image": require("../images/projects/DSR SkyMarq.jpg"),
        "mapLink": "https://maps.google.com/?q=Financial+District,Hyderabad"
    },
    {
        "id": "dsr-the-classe",
        "title": "DSR - The Classe",
        "location": "Kokapet, Hyderabad",
        "description": "DSR - The Classe offers high-end apartments with top-class amenities in Kokapet, Hyderabad.",
        "features": [
            "Luxury apartments",
            "Modern amenities",
            "Close to IT hubs"
        ],
        "image": require("../images/projects/DSR - The Classe.jpg"),
        "mapLink": "https://maps.google.com/?q=Kokapet,Hyderabad"
    },
    {
        "id": "aika-super-luxe-villas",
        "title": "AIKA Super-Luxe Villas",
        "location": "Gandipet, Hyderabad",
        "description": "AIKA Super-Luxe Villas offer opulent living spaces in the serene surroundings of Gandipet.",
        "features": [
            "Super-luxury villas",
            "Lush green surroundings",
            "Private pools"
        ],
        "image": require("../images/projects/AIKA Super-Luxe Villas.jpeg"),
        "mapLink": "https://maps.google.com/?q=Gandipet,Hyderabad"
    },
    {
        "id": "frontline-seven",
        "title": "Frontline Seven",
        "location": "Kokapet, Hyderabad",
        "description": "Frontline Seven offers modern residential spaces in Kokapet, an ideal location for professionals and families.",
        "features": [
            "Modern design",
            "Luxury amenities",
            "Prime location"
        ],
        "image": require("../images/projects/Frontline Seven.jpg"),
        "mapLink": "https://maps.google.com/?q=Kokapet,Hyderabad"
    },
    {
        "id": "greenmark-mayfair-sunrise",
        "title": "Greenmark Developers - Mayfair Sunrise",
        "location": "Kollur, Hyderabad",
        "description": "Mayfair Sunrise by Greenmark Developers offers serene living spaces with modern amenities in Kollur.",
        "features": [
            "Modern amenities",
            "Spacious apartments",
            "Lush green surroundings"
        ],
        "image": require("../images/projects/Greenmark Developers - Mayfair Sunrise.jpg"),
        "mapLink": "https://maps.google.com/?q=Kollur,Hyderabad"
    },
    {
        "id": "hallmark-natures-nest",
        "title": "Hallmark - Nature's Nest",
        "location": "Kollur, Hyderabad",
        "description": "Nature's Nest offers spacious and eco-friendly living spaces in the tranquil area of Kollur.",
        "features": [
            "Eco-friendly design",
            "Ample green space",
            "Close to nature"
        ],
        "image": require("../images/projects/Hallmark - Nature's Nest.png"),
        "mapLink": "https://maps.google.com/?q=Kollur,Hyderabad"
    },
    {
        "id": "kgr-fortune-anandam-villas",
        "title": "KGR Fortune - Anandam Villas",
        "location": "Tukkuguda, Hyderabad",
        "description": "Anandam Villas offers luxurious living spaces with a blend of comfort and elegance in Tukkuguda.",
        "features": [
            "Luxury villas",
            "Spacious design",
            "Peaceful surroundings"
        ],
        "image": require("../images/projects/KGR Fortune - Anandam Villas.jpg"),
        "mapLink": "https://maps.google.com/?q=Tukkuguda,Hyderabad"
    },
    {
        "id": "unknown-project-kukatpally",
        "title": "(Unknown Project)",
        "location": "Kukatpally, Hyderabad",
        "description": "This project in Kukatpally offers high-end residential living in one of Hyderabad’s most vibrant localities.",
        "features": [
            "High-end apartments",
            "Proximity to commercial areas",
            "Modern amenities"
        ],
        "image": require("../images/projects/Urbanspaces - HMDA Plotting.jpg"),
        "mapLink": "https://maps.google.com/?q=Kukatpally,Hyderabad"
    },
    {
        "id": "lansum-elena-residences",
        "title": "Lansum Elena Residences",
        "location": "Jubilee Hills, Hyderabad",
        "description": "Lansum Elena Residences offer luxurious living spaces in the posh locality of Jubilee Hills.",
        "features": [
            "Luxury apartments",
            "Prime location",
            "Modern amenities"
        ],
        "image": require("../images/projects/Lansum Elena Residences.jpg"),
        "mapLink": "https://maps.google.com/?q=Jubilee+Hills,Hyderabad"
    },
    {
        "id": "lenovor-egeira",
        "title": "Lenovor Egeira",
        "location": "Kollur, Hyderabad",
        "description": "Lenovor Egeira provides premium residential living spaces in the rapidly developing area of Kollur.",
        "features": [
            "Premium apartments",
            "Proximity to tech hubs",
            "Spacious design"
        ],
        "image": require("../images/projects/Lenovor Egeira.png"),
        "mapLink": "https://maps.google.com/?q=Kollur,Hyderabad"
    },
    {
        "id": "my-home-apas",
        "title": "My Home APAS",
        "location": "Kokapet, Hyderabad",
        "description": "My Home APAS offers modern living spaces in Kokapet, providing easy access to commercial hubs.",
        "features": [
            "Luxury apartments",
            "Proximity to major business centers",
            "Modern amenities"
        ],
        "image": require("../images/projects/My Home APAS.jpg"),
        "mapLink": "https://maps.google.com/?q=Kokapet,Hyderabad"
    },
    {
        "id": "my-home-grava",
        "title": "My Home Grava",
        "location": "Neopolis, Kokapet, Hyderabad",
        "description": "My Home Grava offers a premium lifestyle with high-rise apartments in Neopolis, Kokapet.",
        "features": [
            "High-rise living",
            "Luxury amenities",
            "Proximity to commercial hubs"
        ],
        "image": require("../images/projects/My Home Grava.jpg"),
        "mapLink": "https://maps.google.com/?q=Neopolis,Kokapet,Hyderabad"
    },
    {
        "id": "my-home-nishada",
        "title": "My Home Nishada",
        "location": "Kokapet, Hyderabad",
        "description": "My Home Nishada offers an exceptional living experience with luxury homes and modern amenities.",
        "features": [
            "Spacious apartments",
            "Modern design",
            "Prime location"
        ],
        "image": require("../images/projects/My Home Nishada.jpg"),
        "mapLink": "https://maps.google.com/?q=Kokapet,Hyderabad"
    },
    {
        "id": "myscape-songs-of-the-sun",
        "title": "Myscape - Songs of the Sun",
        "location": "Financial District, Hyderabad",
        "description": "Myscape - Songs of the Sun offers luxury apartments with scenic views in the Financial District.",
        "features": [
            "Luxury apartments",
            "City views",
            "Close to business hubs"
        ],
        "image": require("../images/projects/Myscape - Songs of the Sun.jpeg"),
        "mapLink": "https://maps.google.com/?q=Financial+District,Hyderabad"
    },
    {
        "id": "navanaami-megaleio",
        "title": "Navanaami Megaleio",
        "location": "Appa Junction, Kokapet, Hyderabad",
        "description": "Navanaami Megaleio offers modern living spaces with a unique design and lifestyle amenities.",
        "features": [
            "Modern design",
            "Luxury amenities",
            "Proximity to tech hubs"
        ],
        "image": require("../images/projects/Navanaami Megaleio.jpg"),
        "mapLink": "https://maps.google.com/?q=Appa+Junction,Kokapet,Hyderabad"
    },
    {
        "id": "poulomi-palazzo",
        "title": "Poulomi Palazzo",
        "location": "Kokapet, Hyderabad",
        "description": "Poulomi Palazzo offers high-end luxury apartments with state-of-the-art amenities in Kokapet.",
        "features": [
            "Luxury apartments",
            "Modern amenities",
            "Prime location"
        ],
        "image": require("../images/projects/Poulomi Palazzo.jpg"),
        "mapLink": "https://maps.google.com/?q=Kokapet,Hyderabad"
    },
    {
        "id": "praera-rnp-stellar",
        "title": "Praera RNP Stellar #1",
        "location": "Kondapur, Hyderabad",
        "description": "Praera RNP Stellar #1 offers premium apartments in the Kondapur area, close to major business hubs.",
        "features": [
            "Luxury apartments",
            "Modern design",
            "Prime location"
        ],
        "image": require("../images/projects/Praera RNP Stellar.jpg"),
        "mapLink": "https://maps.google.com/?q=Kondapur,Hyderabad"
    },
    {
        "id": "prestige-beverly-hills-kokapet",
        "title": "Prestige Beverly Hills",
        "location": "Kokapet, Hyderabad",
        "description": "Prestige Beverly Hills offers luxurious living spaces in the prime area of Kokapet, with modern amenities.",
        "features": [
            "Luxury apartments",
            "Prime location",
            "Modern amenities"
        ],
        "image": require("../images/projects/Prestige Beverly Hills.jpg"),
        "mapLink": "https://maps.google.com/?q=Kokapet,Hyderabad"
    },
    {
        "id": "prestige-clairemont-kokapet",
        "title": "Prestige Clairemont",
        "location": "Kokapet, Hyderabad",
        "description": "Prestige Clairemont offers high-end residences in Kokapet with exceptional design and amenities.",
        "features": [
            "High-end residences",
            "Exclusive design",
            "Top-tier amenities"
        ],
        "image": require("../images/projects/Prestige Clairemont.jpg"),
        "mapLink": "https://maps.google.com/?q=Kokapet,Hyderabad"
    },
    {
        "id": "raghava-iris-raidurgam",
        "title": "Raghava Iris",
        "location": "Raidurgam, Hyderabad",
        "description": "Raghava Iris offers a premium lifestyle in the heart of Raidurgam with modern facilities.",
        "features": [
            "Premium lifestyle",
            "Central location",
            "Modern facilities"
        ],
        "image": require("../images/projects/Raghava Iris.jpg"),
        "mapLink": "https://maps.google.com/?q=Raidurgam,Hyderabad"
    },
    {
        "id": "raghava-sage-kollur",
        "title": "Raghava Sage",
        "location": "Kollur, Hyderabad",
        "description": "Raghava Sage provides luxurious residences in the tranquil locality of Kollur.",
        "features": [
            "Luxurious residences",
            "Tranquil environment",
            "Top amenities"
        ],
        "image": require("../images/projects/Raghava Sage.jpg"),
        "mapLink": "https://maps.google.com/?q=Kollur,Hyderabad"
    },
    {
        "id": "raghava-wave-tellapur",
        "title": "Raghava Wave",
        "location": "Tellapur, Hyderabad",
        "description": "Raghava Wave is a premium residential complex offering a blend of modern amenities and serene surroundings in Tellapur.",
        "features": [
            "Premium residential complex",
            "Modern amenities",
            "Serene surroundings"
        ],
        "image": require("../images/projects/Raghava Wave.jpg"),
        "mapLink": "https://maps.google.com/?q=Tellapur,Hyderabad"
    },
    {
        "id": "raha-tattva-vanya-koyyalagudem",
        "title": "Raha Tattva - VANYA",
        "location": "Koyyalagudem, Hyderabad",
        "description": "Raha Tattva - Vanya offers high-end residential units in Koyyalagudem, blending luxury and tranquility.",
        "features": [
            "High-end units",
            "Tranquil location",
            "Luxury living"
        ],
        "image": require("../images/projects/Raha Tattva - VANYA.jpg"),
        "mapLink": "https://maps.google.com/?q=Koyyalagudem,Hyderabad"
    },
    {
        "id": "rajapushpa-casa-luxura-neopolis-kokapet",
        "title": "Rajapushpa Casa Luxura",
        "location": "Neopolis, Kokapet, Hyderabad",
        "description": "Rajapushpa Casa Luxura offers luxurious living with premium amenities in Neopolis, Kokapet.",
        "features": [
            "Luxurious living",
            "Premium amenities",
            "Exclusive location"
        ],
        "image": require("../images/projects/Rajapushpa Casa Luxura.jpg"),
        "mapLink": "https://maps.google.com/?q=Neopolis,Kokapet,Hyderabad"
    },
    {
        "id": "rajapushpa-infina-manchirevula",
        "title": "Rajapushpa Infina",
        "location": "Manchirevula, Hyderabad",
        "description": "Rajapushpa Infina offers upscale residences in the peaceful locality of Manchirevula.",
        "features": [
            "Upscale residences",
            "Peaceful locality",
            "Modern features"
        ],
        "image": require("../images/projects/Rajapushpa Infina.jpg"),
        "mapLink": "https://maps.google.com/?q=Manchirevula,Hyderabad"
    },
    {
        "id": "rajapushpa-pristinia-financial-district",
        "title": "Rajapushpa Pristinia",
        "location": "Financial District, Hyderabad",
        "description": "Rajapushpa Pristinia offers exclusive apartments in the Financial District with unmatched amenities.",
        "features": [
            "Exclusive apartments",
            "Unmatched amenities",
            "Prime business district"
        ],
        "image": require("../images/projects/Rajapushpa Pristinia.jpg"),
        "mapLink": "https://maps.google.com/?q=Financial District,Hyderabad"
    },
    {
        "id": "rajapushpa-provincia-nanakramguda",
        "title": "Rajapushpa Provincia",
        "location": "Nanakramguda, Hyderabad",
        "description": "Rajapushpa Provincia offers premium living in Nanakramguda with all modern amenities.",
        "features": [
            "Premium living",
            "Modern amenities",
            "Exclusive location"
        ],
        "image": require("../images/projects/Rajapushpa Provincia.jpg"),
        "mapLink": "https://maps.google.com/?q=Nanakramguda,Hyderabad"
    },
    {
        "id": "sas-crown-kokapet",
        "title": "SAS Crown",
        "location": "Kokapet, Hyderabad",
        "description": "SAS Crown is a luxurious project in Kokapet offering world-class amenities and modern living.",
        "features": [
            "Luxurious project",
            "World-class amenities",
            "Modern living"
        ],
        "image": require("../images/projects/SAS Crown.jpg"),
        "mapLink": "https://maps.google.com/?q=Kokapet,Hyderabad"
    },
    {
        "id": "sattva-lakeridge-neopolis-kokapet",
        "title": "Sattva Lakeridge",
        "location": "Neopolis, Kokapet, Hyderabad",
        "description": "Sattva Lakeridge offers stylish and comfortable homes in the Neopolis area of Kokapet.",
        "features": [
            "Stylish homes",
            "Comfortable living",
            "Prime location"
        ],
        "image": require("../images/projects/Sattva Lakeridge.jpg"),
        "mapLink": "https://maps.google.com/?q=Neopolis,Kokapet,Hyderabad"
    },
    {
        "id": "sr-villas-kollur",
        "title": "SR Villas",
        "location": "Kollur, Hyderabad",
        "description": "SR Villas provides independent villas with premium designs and features in the serene location of Kollur.",
        "features": [
            "Independent villas",
            "Premium designs",
            "Serene location"
        ],
        "image": require("../images/projects/SR Villas.jpg"),
        "mapLink": "https://maps.google.com/?q=Kollur,Hyderabad"
    },
    {
        "id": "sri-aditya-aikaa",
        "title": "Sri Aditya - Aikaa",
        "location": "Hyderabad",
        "description": "Sri Aditya - Aikaa offers spacious and modern apartments designed for comfort and luxury.",
        "features": [
            "Spacious apartments",
            "Comfort and luxury",
            "Modern design"
        ],
        "image": require("../images/projects/Sri Aditya - Aikaa.jpg"),
        "mapLink": "https://maps.google.com/?q=Hyderabad"
    },
    {
        id: "sri-srinivasa-infra-fortune-one-banjara-hills",
        title: "Sri Srinivasa Infra - Fortune One",
        location: "Road No. 12, Banjara Hills, Hyderabad",
        description: "Sri Srinivasa Infra - Fortune One offers premium apartments in the heart of Banjara Hills.",
        features: ["Premium apartments", "Heart of Banjara Hills", "Luxury living"],
        image: require("../images/projects/Sri Srinivasa Infra - Fortune One.jpg"),
        mapLink: "https://maps.google.com/?q=Road No. 12, Banjara Hills, Hyderabad"
    },
    {
        id: "ssi-fortune-retreat-mominpet-shankarpally",
        title: "SSI - Fortune Retreat",
        location: "Mominpet, Shankarpally, Hyderabad",
        description: "SSI - Fortune Retreat offers peaceful and luxurious living in the picturesque Mominpet area.",
        features: ["Peaceful living", "Luxurious features", "Picturesque location"],
        image: require("../images/projects/SSI - Fortune Retreat.jpg"),
        mapLink: "https://maps.google.com/?q=Mominpet, Shankarpally, Hyderabad"
    },
    {
        id: "ssi-12-acres-neopolis-kokapet",
        title: "SSI - 12 Acres Neopolis",
        location: "Neopolis, Kokapet, Hyderabad",
        description: "SSI - 12 Acres Neopolis offers spacious and luxurious living in a prime location in Kokapet.",
        features: ["Spacious living", "Luxurious design", "Prime location"],
        image: require("../images/projects/SSI - 12 Acres Neopolis.jpg"),
        mapLink: "https://maps.google.com/?q=Neopolis, Kokapet, Hyderabad"
    },
    {
        id: "ssi-the-marquise-kokapet",
        title: "SSI - The Marquise",
        location: "Kokapet, Hyderabad",
        description: "SSI - The Marquise offers luxurious living spaces with top-notch amenities in Kokapet, Hyderabad.",
        features: ["Luxurious living", "Top-notch amenities", "Prime location"],
        image: require("../images/projects/SSI - The Marquise.jpg"),
        mapLink: "https://maps.google.com/?q=Kokapet,Hyderabad"
    },
    {
        id: "ssi-water-front-kukatpally",
        title: "SSI - Water Front",
        location: "Kukatpally, Hyderabad",
        description: "SSI - Water Front offers a serene living experience with a beautiful waterfront view in Kukatpally.",
        features: ["Waterfront view", "Serene living", "Premium amenities"],
        image: require("../images/projects/SSI - Water Front.jpg"),
        mapLink: "https://maps.google.com/?q=Kukatpally,Hyderabad"
    },
    {
        id: "sumadhura-horizon-kondapur",
        title: "Sumadhura Horizon",
        location: "Kondapur, Hyderabad",
        description: "Sumadhura Horizon offers luxury apartments in the heart of Kondapur with modern amenities.",
        features: ["Luxury apartments", "Modern amenities", "Prime location"],
        image: require("../images/projects/Sumadhura Horizon.jpg"),
        mapLink: "https://maps.google.com/?q=Kondapur,Hyderabad"
    },
    {
        id: "sumadhura-palais-royale-khajaguda",
        title: "Sumadhura - Palais Royale",
        location: "Khajaguda, Hyderabad",
        description: "Sumadhura Palais Royale offers upscale living with sophisticated design and features in Khajaguda.",
        features: ["Upscale living", "Sophisticated design", "Exclusive location"],
        image: require("../images/projects/Sumadhura - Palais Royale.jpg"),
        mapLink: "https://maps.google.com/?q=Khajaguda,Hyderabad"
    },
    {
        id: "sunshine-destino-puppalaguda-gandipet",
        title: "Sunshine Destino",
        location: "Puppalaguda, Gandipet, Hyderabad",
        description: "Sunshine Destino offers a peaceful and luxurious lifestyle in Puppalaguda, near the serene Gandipet lake.",
        features: ["Peaceful lifestyle", "Luxurious living", "Serene location"],
        image: require("../images/projects/Sunshine Destino.jpg"),
        mapLink: "https://maps.google.com/?q=Puppalaguda,Gandipet,Hyderabad"
    },
    {
        id: "the-vue-residences-nanakramguda",
        title: "The VUE Residences",
        location: "Nanakramguda, Hyderabad",
        description: "The VUE Residences provide contemporary living with stunning views and modern amenities in Nanakramguda.",
        features: ["Contemporary living", "Stunning views", "Modern amenities"],
        image: require("../images/projects/The VUE Residences.jpg"),
        mapLink: "https://maps.google.com/?q=Nanakramguda,Hyderabad"
    },
    {
        id: "trendset-allure-neopolis-kokapet",
        title: "Trendset Allure",
        location: "Neopolis, Kokapet, Hyderabad",
        description: "Trendset Allure offers a mix of luxury and comfort with world-class amenities in Kokapet.",
        features: ["Luxury living", "World-class amenities", "Comfortable design"],
        image: require("../images/projects/Trendset Allure.jpg"),
        mapLink: "https://maps.google.com/?q=Neopolis,Kokapet,Hyderabad"
    },
    {
        id: "urbanspaces-high-garden-chevella",
        title: "Urbanspaces - High Garden",
        location: "Chevella, Hyderabad",
        description: "Urbanspaces - High Garden offers luxurious residences with a blend of nature and modern design in Chevella.",
        features: ["Luxurious residences", "Blend of nature", "Modern design"],
        image: require("../images/projects/Urbanspaces - High Garden.jpg"),
        mapLink: "https://maps.google.com/?q=Chevella,Hyderabad"
    },
    {
        id: "urbanspaces-hmda-plotting-chevella",
        title: "Urbanspaces - HMDA Plotting",
        location: "Chevella, Hyderabad",
        description: "Urbanspaces - HMDA Plotting offers prime plots for residential development in the growing area of Chevella.",
        features: ["Prime plots", "Residential development", "Growing locality"],
        image: require("../images/projects/Urbanspaces - HMDA Plotting.jpg"),
        mapLink: "https://maps.google.com/?q=Chevella,Hyderabad"
    },
    {
        id: "vamsiram-builders-manhattan-khajaguda-gachibowli",
        title: "Vamsiram Builders - Manhattan",
        location: "Khajaguda, Gachibowli, Hyderabad",
        description: "Vamsiram Builders - Manhattan offers contemporary living with world-class amenities in Gachibowli.",
        features: ["Contemporary living", "World-class amenities", "Prime location"],
        image: require("../images/projects/Vamsiram Builders - Manhattan.jpg"),
        mapLink: "https://maps.google.com/?q=Khajaguda,Gachibowli,Hyderabad"
    },
    {
        id: "vamsiram-homes-the-niche-shaikpet",
        title: "Vamsiram Homes - The Niche",
        location: "Shaikpet, Hyderabad",
        description: "Vamsiram Homes - The Niche offers modern living with luxurious features in Shaikpet.",
        features: ["Modern living", "Luxurious features", "Exclusive locality"],
        image: require("../images/projects/Vamsiram Homes - The Niche.jpg"),
        mapLink: "https://maps.google.com/?q=Shaikpet,Hyderabad"
    },
    {
        id: "vasavi-ananda-nilayam-l-b-nagar",
        title: "Vasavi Ananda Nilayam",
        location: "L B Nagar, Hyderabad",
        description: "Vasavi Ananda Nilayam offers a blend of comfort and luxury in the well-connected area of L B Nagar.",
        features: ["Comfort and luxury", "Well-connected location", "Modern amenities"],
        image: require("../images/projects//Vasavi Ananda Nilayam.jpg"),
        mapLink: "https://maps.google.com/?q=L B Nagar,Hyderabad"
    },
    {
        id: "vasavi-atlantis-narsingi",
        title: "Vasavi Atlantis",
        location: "Narsingi, Hyderabad",
        description: "Vasavi Atlantis offers luxurious apartments in the peaceful area of Narsingi, Hyderabad.",
        features: ["Luxurious apartments", "Peaceful environment", "Exclusive location"],
        image: require("../images/projects/Vasavi Atlantis.jpg"),
        mapLink: "https://maps.google.com/?q=Narsingi,Hyderabad"
    },
    {
        id: "western-springs-nanakramguda-financial-district",
        title: "Western Springs",
        location: "Nanakramguda, Financial District, Hyderabad",
        description: "Western Springs offers a modern living experience in Hyderabad’s Financial District with excellent connectivity.",
        features: ["Modern living", "Financial District", "Excellent connectivity"],
        image: require("../images/projects/Western Springs.png"),
        mapLink: "https://maps.google.com/?q=Nanakramguda,Financial District,Hyderabad"
    }



]



export default projects;
